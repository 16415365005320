import React from "react";
import {Table, TableColumn} from "../tables/Table";
import {ProgressButton} from "../ui/buttons/ProgressButton";
import {useNavigate} from "react-router-dom";
import {TablePage} from "../../model/utils/TablePage";
import {ProductionOrderJournalRow} from "../../model/production-order/ProductionOrder";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {useSearchProductionStagesQueueQuery} from "../../store/backend-api/backend.api";

interface TableProps {
    data: TablePage<ProductionOrderJournalRow>
}

export function ProductionOrderArchiveTable(props: TableProps) {
    const {updateFilterPage} = useFiltersActions()
    const {data: productionStagesQueue} = useSearchProductionStagesQueueQuery();

    const navigate = useNavigate()

    const workOrderNumberColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: '№ Work',
        extractCellContent: row => row.workOrderNumber
    }

    const productionLineColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Пр. линия',
        title: 'Производственная линия',
        extractCellContent: row => row.productionLine?.name || "–"
    }

    const mainProductColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Основная продукция',
        extractCellContent: row => row.mainProduct ? `${row.mainProduct?.nomenclature?.name}, ${row.mainProduct?.nomenclature?.measurementUnit?.name || "–"}` : "–"
    }

    const additionalEquipmentColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Доп. комплектация',
        title: 'Дополнительная комплектация',
        displayAtList: true,
        additionalStyles: 'max-w-[300px] w-max',
        extractCellContent: row => row.additionalEquipment.length === 0 ? "–" : row.additionalEquipment.map(equipment => equipment.nomenclature.name)
    }

    const productionTaskProgressColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Прогресс выполнения заказа',
        showOnOneTableRow: true,
        extractCellContent: row =>
            productionStagesQueue?.queue?.map(stage => {
                const progress = row.progress.find(progress => progress.productionStage.id === stage.id);
                return (<ProgressButton data={progress}
                                        productionStage={{id: stage.id, name: stage.name}}
                                        key={stage.order}/>)
            }) || '-'
    }

    const planningProductionEndTimeColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Требуется завершить',
        extractCellContent: row => {
            const date = new Date(row.planningCompletionDate)
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}.${month}.${year}`
        }
    }

    const productionEndTimeColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Завершён',
        extractCellContent: row => row.completionTime
    }

    function actionOnRowClick(row: ProductionOrderJournalRow) {
        navigate(`/production-order?number=${row.documentNumber}`)
    }

    return (
        <Table<ProductionOrderJournalRow>
            tableColumns={[workOrderNumberColumn, productionLineColumn, mainProductColumn, additionalEquipmentColumn, productionTaskProgressColumn, planningProductionEndTimeColumn, productionEndTimeColumn]}
            rows={props.data.rows}
            onRowClick={actionOnRowClick}
            tableFilterKey={TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => {
                    updateFilterPage({key: TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER, value: newPageNumber})
                }
            }}
        />
    )
}