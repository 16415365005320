import React, {ReactNode} from "react";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {ObservableElement} from "../ui/table-components/ObservableElement";
import {useAppSelector} from "../../hooks/useAppSelector";
import {SortButton} from "../ui/table-components/SortButton";
import {Popup} from "../ui/common/Popup/Popup";

export interface TableProps<T> {
    tableColumns: TableColumn<T>[]
    rows: T[],
    onRowClick?: (row: T) => void
    pagination: Pagination,
    tableFilterKey: TableFilterKeys,
    getTableRowBgColor?: (row: T) => string,
    getTableRowTitle?: (row: T) => string,
    sortRows?: SortRows<T>,
}

export interface TableColumn<T> {
    columnName: React.ReactNode
    title?: string
    columnFieldName?: string
    sortingFunction?: () => void
    extractCellContent: (row: T) => ReactNode | any[],
    alignmentInCell?: string
    additionalStyles?: string
    additionalHeaders?: string[]
    additionalHeadersStyles?: string[],
    displayAtList?: boolean,
    showOnOneTableRow?: boolean
    popupContent?: React.ReactNode;
}

export interface Pagination {
    currentPageNumber: number
    quantityOfPages: number
    setPageNumber: (newPageNumber: number) => void
}

export interface SortRows<T> {
    sort: (rows: T[]) => T[][],
    separators: ReactNode[]
}

export function Table<T>({rows = [], ...props}: TableProps<T>) {
    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[props.tableFilterKey].filter;

    function columnNameMapper(tableColumn: TableColumn<T>): ReactNode {
        return (
            <th title={typeof tableColumn.columnName === "string" && !tableColumn.title ? tableColumn.columnName : tableColumn.title}
                key={typeof tableColumn.columnName === "string" ? tableColumn.columnName : tableColumn.title!}
                className={`align-top whitespace-nowrap pb-1 pt-1.5 px-1.5 font-normal text-left`}>
                <div className='flex flex-row gap-0.5 items-center'>
                    {tableColumn.popupContent ?
                        <Popup caption={<div
                            className={`${tableColumn.additionalStyles} underline underline-offset-1`}>{tableColumn.columnName}</div>}
                               position='bottom' cssPosition='fixed' gap={3}>{tableColumn.popupContent}</Popup> :
                        <div className={`${tableColumn.additionalStyles}`}>{tableColumn.columnName}</div>}
                    <SortButton sortingFunction={tableColumn.sortingFunction} filter={filter}
                                fieldName={tableColumn.columnFieldName}/></div>
            </th>
        )
    }

    function rowMapper(row: T, index: number, tableColumns: TableColumn<T>[], onRowClick?: (row: T) => void): ReactNode {
        const rowStyle = onRowClick ? "hover:bg-blue-500/[.10] cursor-pointer" : ""
        return (
            <tr key={index}
                className={`font-light text-left ${rowStyle} ${props.getTableRowBgColor?.(row) || ''}`}
                onClick={onRowClick ? () => onRowClick(row) : undefined} title={props.getTableRowTitle?.(row) || ''}>
                {tableColumns.map((tableColumn, index) => cellMapper(row, tableColumn, index, tableColumn.alignmentInCell))}
            </tr>
        )
    }

    function cellMapper(row: T, tableColumn: TableColumn<T>, index: number, alignmentInCell?: string): ReactNode {
        const columnValue = tableColumn.extractCellContent(row)
        if (Array.isArray(columnValue)) {
            return (
                <td key={index}
                    className={`px-1.5 py-1 border-t-[0.5px] border-t-black/[.20] align-middle ${alignmentInCell}`}>
                    {tableColumn.displayAtList && columnValue.map((value, index) => <div
                        className={`${tableColumn.additionalStyles}`}
                        key={index}>{`${index + 1}. ${value}`}<br/></div>)}
                    {tableColumn.showOnOneTableRow &&
                        <div
                            className={`flex flex-row ${tableColumn.additionalStyles}`}>{columnValue.map((value, index) =>
                            <span
                                key={index}>{value}</span>)}</div>}
                    {!tableColumn.displayAtList && !tableColumn.showOnOneTableRow && columnValue.map((value, index) =>
                        <div key={index} className={tableColumn.additionalStyles}>{value}<br/></div>)}
                </td>
            )
        }
        return (
            <td key={index}
                className={`px-1.5 py-1 border-t-[0.5px] border-t-black/[.20] align-middle ${alignmentInCell}`}>{tableColumn.extractCellContent(row) || "–"}
            </td>)
    }

    return (<>
        <div
            className={`bg-white min-w-full max-h-full rounded-md shadow-lg px-2 pb-3 overflow-auto text-sm leading-4`}>
            <table className="min-w-full max-h-full border-b-[0.5px] border-b-black/[.20]">
                <thead className="sticky top-0 bg-white z-100">
                <tr>
                    {props.tableColumns.map((column) => columnNameMapper(column))}
                </tr>
                </thead>
                <tbody>
                {!props.sortRows && rows.map((row, index) => rowMapper(row, index, props.tableColumns, props.onRowClick))}
                {props.sortRows && props.sortRows.sort(rows).map((rowsGroup, index) => [props.sortRows!.separators[index]].concat(rowsGroup.map((row, index) => rowMapper(row, index, props.tableColumns, props.onRowClick))))}
                </tbody>
            </table>
            <ObservableElement currentPageNumber={props.pagination.currentPageNumber}
                               quantityOfPages={props.pagination.quantityOfPages}
                               setPageNumber={props.pagination.setPageNumber}/>
        </div>
    </>)
}