import React, {ReactNode, useEffect, useRef, useState} from "react";
import {SearchInput} from "./SearchInput";
import {getFilterType, TableFilterKeys} from "../../../model/filters/TableFiltersDefinitions";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {useFiltersActions} from "../../../hooks/useFiltersActions";
import {useNavigate} from "react-router-dom";
import {Button} from "../buttons/Button";
import {PiInfoThin} from "react-icons/pi";

export interface BoxProps {
    createNavigationURL?: string,
    objectInsteadOfSearchLine?: ReactNode
    additionalButtons?: AdditionalButton[],
    tableFilterKey: TableFilterKeys,
    searchInfo?: string
}

interface AdditionalButton {
    button: ReactNode,
    condition?: () => boolean
}

export function GuideTopBox(props: BoxProps) {
    const {updateFilterAndClearData, resetFilterToDefault, toggleFiltersVisibility} = useFiltersActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[props.tableFilterKey].filter
    const haveAdditionalFiltersMenu = state[props.tableFilterKey].haveAdditionalFiltersMenu

    const [search, setSearch] = useState<string>(filter.search ?? '');
    const [isVisible, setIsVisible] = useState(false)
    const infoIconRef = useRef<HTMLDivElement>(null)
    const infoBoxRef = useRef<HTMLDivElement>(null)

    const navigate = useNavigate()

    function onSearchInput(text: string | null) {
        setSearch(text || "")
    }

    useEffect(() => {
        if (search === filter.search) return;
        setSearch(filter.search || '')
    }, [filter.search])

    useEffect(() => {
        if (search !== filter.search) {
            let timerForSearchDebounce = setTimeout(() => {
                updateFilterAndClearData({
                    key: props.tableFilterKey,
                    value: {...filter, 'pageNumber': 0, 'search': search}
                })
            }, 500);
            return () => {
                clearTimeout(timerForSearchDebounce);
            };
        }
    }, [search]);

    useEffect(() => {
        if (isVisible && infoIconRef.current && infoBoxRef.current) {
            const iconRect = infoIconRef.current.getBoundingClientRect();
            infoBoxRef.current.style.top = `${iconRect.bottom + window.scrollY + 10}px`;
            infoBoxRef.current.style.left = `${iconRect.left + window.scrollX}px`;
        }
    }, [isVisible]);

    return (
        <div className="min-w-full min-h-fit flex flex-wrap flex-row bg-white rounded-md shadow-lg p-2 overflow-auto gap-y-3 gap-x-6">
            {!props.objectInsteadOfSearchLine ?
                <div className="grow min-w-[300px] max-w-[500px] h-6 flex flex-row gap-x-3 items-center">
                    <SearchInput placeholder="Поиск по таблице" onChange={onSearchInput}
                                 value={search}/>
                    {props.searchInfo && <div>
                        <div ref={infoIconRef}><PiInfoThin className="h-5 w-5 text-black/[.60]"
                                                           onMouseOver={() => setIsVisible(true)}
                                                           onMouseOut={() => setIsVisible(false)}/></div>
                        {isVisible && <div ref={infoBoxRef}
                                           className="shadow-lg leading-4 text-sm font-normal text-black/[.67] opacity-100 z-50 bg-white border-[1px] border-blue-500 rounded absolute px-2 py-1 translate-x-[-45%] max-w-[300px]">
                            {props.searchInfo}
                        </div>}
                    </div>}
                </div>
                : props.objectInsteadOfSearchLine}

            <div className="flex flex-row ml-auto my-auto gap-x-3">
                {props.additionalButtons && props.additionalButtons.filter(button => button.condition ? button.condition() : true).map(button => button.button)}

                {haveAdditionalFiltersMenu && <>
                    <Button
                        onClickButton={() => {
                            if (JSON.stringify(filter) !== JSON.stringify(getFilterType(props.tableFilterKey))) {
                                resetFilterToDefault(props.tableFilterKey)
                            }
                        }}
                        buttonName={"Сбросить фильтры"}
                        additionalStyles={"ml-0"}
                    />
                    <Button
                        onClickButton={() => toggleFiltersVisibility(props.tableFilterKey)}
                        buttonName={"Фильтры"}
                        additionalStyles={"ml-0"}
                    />
                </>}

                {props.createNavigationURL && <Button
                    onClickButton={() => navigate(props.createNavigationURL!)}
                    buttonName={"Создать"}
                    additionalStyles={"ml-0"}
                />}
            </div>
        </div>
    )
}


