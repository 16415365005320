import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm, useWatch} from "react-hook-form";
import {
    useCreateBillOfMaterialsMutation,
    useDeleteTechnologicalProcessChartMutation,
    useUpdateProductOfBillOfMaterialsMutation
} from "../../store/backend-api/backend.api";
import {OptionType} from "../../model/option-type/OptionType";
import {Button} from "../ui/buttons/Button";
import {useNavigate} from "react-router-dom";
import {
    EditProductOfBillOfMaterialsRequest,
    Product
} from "../../model/technological-process-chart/TechnologicalProcessChart";
import ReactModal from "react-modal";
import {NomenclatureForm} from "../../model/nomenclature/NomenclatureForm";
import {SeparatingLine} from "../ui/common/SeparatingLine";
import {TargetProductForm} from "./TargetProductForm";
import {ModalForCopyTechnologicalProcessChart} from "./ModalForCopyTechnologicalProcessChart";
import {ModalForCreateNomenclatureFromSelect} from "./ModalForCreateNomenclatureFromSelect";
import {ModalForEditNomenclature} from "./ModalForEditNomenclature";
import {formButtonGroupStyle} from "../../utils/TailwindStyles";

interface FormProps {
    targetProduct?: Product,
    nomenclature?: NomenclatureForm
}

interface FormFields {
    targetProductName: OptionType<number> | null,
    productQuantity: string
}

export function BasicInformationFormTemplate(props: FormProps) {
    const [doCreateBillOfMaterials, {
        data: technologicalProcessChartId,
        isSuccess: isSuccessCreate
    }] = useCreateBillOfMaterialsMutation()
    const [doUpdateProductOfBillOfMaterials] = useUpdateProductOfBillOfMaterialsMutation()
    const [doDeleteTechnologicalProcessChart, {isSuccess: isSuccessDelete}] = useDeleteTechnologicalProcessChartMutation()
    const {control, handleSubmit, setValue, resetField, formState: {isDirty}} = useForm<FormFields>({
        mode: "onChange",
        defaultValues: props.targetProduct !== undefined ? {
            targetProductName: {
                label: `${props.targetProduct.nomenclature.fullName}, ${props.targetProduct.nomenclature.measurementUnit.name}`,
                value: props.targetProduct.nomenclature.id
            },
            productQuantity: props.targetProduct.quantity
        } : {targetProductName: null, productQuantity: ""}
    })
    const [modalForCopyIsOpen, setModalForCopyIsOpen] = useState<boolean>(false)
    const [modalForEditNomenclatureIsOpen, setModalForEditNomenclatureIsOpen] = useState<boolean>(false)
    const [modalForCreateNomenclatureIsOpen, setModalForCreateNomenclatureIsOpen] = useState<boolean>(false)
    const [createdNomenclatureOption, setCreatedNomenclatureOption] = useState<OptionType<number> | null>(null)
    const onClickCreateButton: SubmitHandler<FormFields> = data => {
        doCreateBillOfMaterials({nomenclatureId: data.targetProductName!.value, quantity: data.productQuantity!})
    }
    const onClickSaveButton: SubmitHandler<FormFields> = data => {
        const product: EditProductOfBillOfMaterialsRequest = {
            quantity: data.productQuantity!,
            nomenclatureId: data.targetProductName!.value,
            size: {
                value: props.targetProduct!.size.value,
                measurementUnitId: props.targetProduct!.size.measurementUnit !== null ? props.targetProduct!.size.measurementUnit.id : null
            },
            isMaterialIn: props.targetProduct!.isMaterialIn,
            isProductIn: props.targetProduct!.isProductIn,
            tariff: props.targetProduct!.tariff
        }
        doUpdateProductOfBillOfMaterials({
            billOfMaterialsId: props.targetProduct!.billOfMaterialsId,
            productId: props.targetProduct!.id,
            body: product
        })
    }

    function onClickDeleteButton() {
        const answer: boolean = window.confirm("Вы уверены, что хотите удалить данную технологическую карту?")
        if (answer) {
            doDeleteTechnologicalProcessChart(props.targetProduct!.billOfMaterialsId)
        }
    }

    const currentFormValue = useWatch({control})
    const navigate = useNavigate()

    ReactModal.defaultStyles = {
        overlay: {
            ...ReactModal.defaultStyles.overlay,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            maxHeight: "90%",
            overflowX: 'auto',
            overflowY: 'auto'
        }
    }

    useEffect(() => {
        if (isSuccessCreate) {
            navigate(`/bill-of-materials?id=${technologicalProcessChartId}`)
        }
        if (isSuccessDelete) {
            navigate(`/technological-process-chart/guide`)
        }
    }, [isSuccessCreate, isSuccessDelete]);

    useEffect(() => {
        if (currentFormValue.targetProductName?.value === -1) {
            setModalForCreateNomenclatureIsOpen(true)
        }
    }, [currentFormValue]);

    useEffect(() => {
        if (!modalForCreateNomenclatureIsOpen && createdNomenclatureOption !== null) {
            setValue("targetProductName", createdNomenclatureOption)
            setCreatedNomenclatureOption(null)
        }
        if (!modalForCreateNomenclatureIsOpen && createdNomenclatureOption === null) {
            resetField("targetProductName")
        }
    }, [modalForCreateNomenclatureIsOpen]);

    return (
        <form onSubmit={handleSubmit(onClickCreateButton)} className="pt-[18px] h-full">
            <TargetProductForm control={control} targetProduct={props.targetProduct}
                               setModalForNomenclatureEditIsOpen={setModalForEditNomenclatureIsOpen}/>
            <SeparatingLine forSeparatingButtons={true}/>
            <div className="flex flex-row justify-between my-3">
                    <Button buttonName={"Редактировать номенклатуру"}
                            onClickButton={() => setModalForEditNomenclatureIsOpen!(true)}/>
                <div className='flex flex-row justify-end mr-2'>
                    {(!props.targetProduct) && (
                        <Button buttonName={"Создать"} onClickButton={handleSubmit(onClickCreateButton)}/>)}
                    {(props.targetProduct) && (<>
                        <Button buttonName={"Дублировать"} onClickButton={() => setModalForCopyIsOpen(true)}/>
                        <Button buttonName={"Удалить"} onClickButton={onClickDeleteButton}/>
                    </>)}
                    {(props.targetProduct && isDirty) && (
                        <Button buttonName={"Сохранить"} onClickButton={handleSubmit(onClickSaveButton)}/>)}
                </div>
            </div>
            <ModalForCreateNomenclatureFromSelect modalForNomenclatureCreateIsOpen={modalForCreateNomenclatureIsOpen}
                                                  setModalForNomenclatureCreateIsOpen={setModalForCreateNomenclatureIsOpen}
                                                  setCreatedNomenclatureOption={setCreatedNomenclatureOption}/>
            {props.targetProduct && <ModalForCopyTechnologicalProcessChart modalForCopyIsOpen={modalForCopyIsOpen}
                                                                           setModalForCopyIsOpen={setModalForCopyIsOpen}
                                                                           targetProduct={props.targetProduct}/>}
            {props.nomenclature && <ModalForEditNomenclature nomenclature={props.nomenclature}
                                                             modalForNomenclatureEditIsOpen={modalForEditNomenclatureIsOpen}
                                                             setModalForNomenclatureEditIsOpen={setModalForEditNomenclatureIsOpen}/>}
        </form>
    )
}