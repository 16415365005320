import {css} from "@emotion/css";

export const styles = {
    popup: (position: string) => css`
      position: ${position};
      z-index: 1000;
      background-color: white;
      border: 1px solid rgba(59, 130, 246, 0.7);
      border-radius: 5px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
      padding: 10px;
    `,
    backdrop: () => css`
      position: fixed;
      z-index: 950;
      background-color: transparent;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    `,
};