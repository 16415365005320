import React, {useEffect, useRef, useState} from "react";
import {ProductionStageProgress} from "../../../model/production-order/ProductionOrder";
import {ProductionStage} from "../../../model/production-stage/ProductionStage";

export interface ProgressButtonProps {
    data?: ProductionStageProgress,
    productionStage?: ProductionStage,
}

export function ProgressButton(props: ProgressButtonProps) {
    const [isVisible, setIsVisible] = useState(false)
    const progressButtonRef = useRef<HTMLDivElement>(null)
    const infoBoxRef = useRef<HTMLDivElement>(null)

    function colorButton(progress: ProductionStageProgress) {
        if (progress.percentageProgress === 0) {
            return "bg-gray-400/[.50]"
        }
        if (progress.percentageProgress === 100) {
            return "bg-green-500/[.60]"
        }
        return "bg-yellow-200"
    }

    function productionStageNameAbbreviation(progress: ProductionStageProgress) {
        const abbreviation = progress.productionStage.name[0] || "–"
        const indicationOfPresencePostponedTasks = progress.hasPostponedTask ? "!" : "";
        return abbreviation + indicationOfPresencePostponedTasks;
    }

    useEffect(() => {
        if (isVisible && progressButtonRef.current && infoBoxRef.current) {
            const buttonRect = progressButtonRef.current.getBoundingClientRect()
            infoBoxRef.current.style.top = `${buttonRect.bottom + window.scrollY + 5}px`
            infoBoxRef.current.style.left = `${buttonRect.left + window.scrollX}px`
        }
    }, [isVisible]);

    if (!props.data) {
        return (<div className={`my-1 ml-0 mr-[1px] w-6 h-6 rounded-[2px] flex justify-center border-[0.5px] border-black.[/.6]`}>
            <p className="my-auto">{props.productionStage?.name[0] || "?"}</p>
        </div>)
    }

    return (
        <div>
            <div ref={progressButtonRef} onMouseOver={() => setIsVisible(true)} onMouseOut={() => setIsVisible(false)}
                 className={`my-1 ml-0 mr-[1px] w-6 h-6 rounded-[2px] flex justify-center ${colorButton(props.data)}`}>
                <p className="my-auto">{productionStageNameAbbreviation(props.data)}</p>
            </div>
            {isVisible && <div ref={infoBoxRef}
                               className={`max-w-28 absolute translate-x-[-40%] bg-white rounded border-blue-500 border-[1px] z-50 px-2 py-1 mt-1 shadow-lg`}>
                <p>{props.data.productionStage.name || "–"}</p>
                <p>Прогресс: {props.data.percentageProgress}%</p>
                <p>Затраченное время: {props.data.timeSpent || "–"}</p>
            </div>}
        </div>
    )
}