import React, {useCallback, useEffect, useRef, useState} from "react";
import {styles} from "./Popup.styles";

type PopupPosition = 'top' | 'bottom' | 'right' | 'left';

type CssPosition = 'absolute' | 'fixed';

interface Props {
    caption: React.ReactNode;
    children: React.ReactNode;
    position: PopupPosition;
    cssPosition?: CssPosition;
    gap?: number;
}

export const Popup = ({caption, children, position, cssPosition = 'absolute', gap = 0}: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const popupRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const setPopupPosition = useCallback(() => {
        if (!buttonRef.current || !popupRef.current) return;
        
        const buttonRect = buttonRef.current.getBoundingClientRect();
        console.log(buttonRect)
        const popup = popupRef.current;
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let top = 0;
        let left = 0;

        switch (position) {
            case 'top':
                top = buttonRect.top - popup.offsetHeight - gap;
                left = buttonRect.left - 5;
                break;
            case 'bottom':
                top = buttonRect.bottom + gap;
                left = buttonRect.left - 5;
                break;
            case 'left':
                top = buttonRect.top + buttonRect.height / 2 - popup.offsetHeight / 2;
                left = buttonRect.left - popup.offsetWidth - gap;
                break;
            case 'right':
                top = buttonRect.top + buttonRect.height / 2 - popup.offsetHeight / 2;
                left = buttonRect.right + gap;
                break;
            default:
                break;
        }

        if (top < 0) top = 0;
        if (left < 0) left = 0;
        if (top + popup.offsetHeight > viewportHeight)
            top = viewportHeight - popup.offsetHeight;
        if (left + popup.offsetWidth > viewportWidth)
            left = viewportWidth - popup.offsetWidth;

        console.log(top, left)

        popup.style.top = `${top}px`
        popup.style.left = `${left}px`
    }, [position]);

    useEffect(() => {
        if (!isOpen) return;
        setPopupPosition();

        window.addEventListener('resize', setPopupPosition);
        return window.removeEventListener('resize', setPopupPosition);
    }, [isOpen, position]);

    return (
        <>
            <button type='button' ref={buttonRef} onClick={() => setIsOpen(true)}>{caption}</button>
            {isOpen && <div className={styles.backdrop()} onClick={() => setIsOpen(false)}><div ref={popupRef} className={styles.popup(cssPosition)} onClick={(e) => e.stopPropagation()}>{children}</div></div>}
        </>
    );
};