import React from "react";
import {Control, Controller} from "react-hook-form";
import {ProductionNomenclatureClassifier} from "../../../../model/nomenclature/NomenclatureForm";

interface SelectProps<T> {
    control: Control<any>,
    rules: {},
    name: string,
    label?: string,
    readonly?: boolean,
    options: any[],
    optionsToStringMap: Map<T, string>,
    additionalStyles?: string,
    defaultValue?: (ProductionNomenclatureClassifier | null)[]
}


export function ControlledMultiSelect<T>(props: SelectProps<T>) {
    const borderColor: string = props.readonly ? "border-gray-300" : "border-gray-400/[.80]"
    const labelStyles: string = props.readonly ? "" : "hover:bg-blue-500/[.15] cursor-pointer"

    return (
        <Controller control={props.control} name={props.name} rules={props.rules} defaultValue={props.defaultValue !== undefined ? props.defaultValue : undefined}
                    render={({field: {value, onChange}, fieldState: {error}}) =>
                        <div className={`${props.additionalStyles}`}>
                            <p className="text-sm font-normal">{props.label}</p>
                            <div className={`${borderColor} ${props.additionalStyles} mt-[2px] border-2 rounded-md w-max-[423px]`}>
                                {props.options.map((option, index) =>
                                    <label key={option} className={`${labelStyles} rounded-md px-3 py-1 border-2 border-white w-full text-sm font-light flex flex-row items-center`}>
                                        <input type="checkbox" value={option} onChange={(event) => {
                                            const newValue = value
                                            newValue[index] = event.target.checked ? event.target.value : null
                                            onChange(newValue)
                                        }} className={`${borderColor} w-[18px] h-[18px] mr-2`}
                                               checked={value.includes(option)} key={option}
                                               readOnly={props.readonly !== undefined ? props.readonly : false}
                                               disabled={props.readonly !== undefined ? props.readonly : false}/>
                                        {props.optionsToStringMap.get(option)}</label>)}
                            </div>
                            {error && <p className="text-sm font-normal text-red-400">{error.message}</p>}
                        </div>
                    }/>
    )
}