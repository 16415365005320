import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {BackButton} from "../ui/buttons/BackButton";
import {useNavigate} from "react-router-dom";
import {AdministrativeRolesRow} from "../../model/administrative-roles/AdministrativeRole";
import {ControlledInputField} from "../ui/form-components/controlled/ControlledInputField";
import {Button} from "../ui/buttons/Button";
import {
    useCreateUserMutation,
    useLazySearchAdministrativeRolesGuidePageQuery,
    useLazySearchIndividualsGuidePageQuery,
    useUpdateUserMutation,
} from "../../store/backend-api/backend.api";
import {ActivityStatus, CreatingOrUpdatingUser, User} from "../../model/users/User";
import {ControlledSelect} from "../ui/form-components/controlled/ControlledSelect";
import {OptionType} from "../../model/option-type/OptionType";
import {activityStatusesToString} from "../../data/UserFormData";
import {ControlledPaginateAsyncSelect} from "../ui/form-components/controlled/ControlledPaginateAsyncSelect";
import {TablePage} from "../../model/utils/TablePage";
import {SeparatingLine} from "../ui/common/SeparatingLine";
import {IndividualsRow} from "../../model/individuals/Individuals";
import {PiEyeLight, PiEyeSlashLight} from "react-icons/pi";
import {formButtonGroupStyle, formHeaderStyle, formStyle} from "../../utils/TailwindStyles";

interface FormProps {
    data?: User,
    subject?: OptionType<number>,
    readonly?: boolean,
}

interface FormFields {
    id: number | null,
    isAdmin: boolean,
    username: string,
    password: string,
    administrativeRole: OptionType<number> | null,
    subject: OptionType<number> | null,
    email: string,
    phoneNumber: string,
    activityStatus: OptionType<ActivityStatus>,
    comment: string
}

export function UserFormTemplate(props: FormProps) {
    const [doUpdateUser, {isSuccess: isSuccessUpdate}] = useUpdateUserMutation()
    const [doCreateUser, {
        data: createdUserId,
        isSuccess: isSuccessCreate
    }] = useCreateUserMutation()
    const [doSearchAdministrativeRolesGuidePage] = useLazySearchAdministrativeRolesGuidePageQuery()
    const [doSearchIndividualGuidePage] = useLazySearchIndividualsGuidePageQuery()
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const defaultFormValues: FormFields = (props.data && props.subject) ? {
        id: props.data.id,
        isAdmin: props.data.isAdmin,
        username: props.data.username,
        password: '',
        administrativeRole:
            props.data.administrativeRole ?
                {
                    label: props.data.administrativeRole.name,
                    value: props.data.administrativeRole.id
                } : null,
        subject: props.subject,
        email: props.data.email,
        phoneNumber: props.data.phoneNumber,
        activityStatus: {
            label: activityStatusesToString.get(props.data.activityStatus)!,
            value: props.data.activityStatus
        },
        comment: props.data.comment
    } : {
        id: null,
        isAdmin: false,
        username: '',
        password: '',
        administrativeRole: null,
        subject: null,
        email: '',
        phoneNumber: '',
        activityStatus: {
            label: activityStatusesToString.get(ActivityStatus.ACTIVE)!,
            value: ActivityStatus.ACTIVE
        },
        comment: ''
    }
    const {control, handleSubmit, formState: {isDirty}} = useForm<FormFields>({
        mode: "onChange",
        defaultValues: defaultFormValues
    })
    const onClickSaveButton: SubmitHandler<FormFields> = data => {
        const newUser: CreatingOrUpdatingUser = {
            username: data.username.trim(),
            password: data.password || null,
            email: data.email?.trim() || null,
            phoneNumber: data.phoneNumber?.trim() || null,
            activityStatus: ActivityStatus[data.activityStatus.value],
            comment: data.comment,
            administrativeRoleId: data.administrativeRole ? data.administrativeRole.value : null,
            subjectId: data.subject!.value
        }
        if (props.data && isDirty) {
            doUpdateUser({id: props.data.id, body: newUser})
        } else if (!props.data) {
            doCreateUser(newUser)
        }
    }
    const optionsForActivityStatusSelect: OptionType<ActivityStatus>[] = Array.from(activityStatusesToString.keys()).map(key => ({
        label: activityStatusesToString.get(ActivityStatus[key])!,
        value: key
    }))

    useEffect(() => {
        if (isSuccessUpdate) {
            navigate(`/user?id=${props.data!.id}`)
        }
        if (isSuccessCreate) {
            navigate(`/user?id=${createdUserId}`)
        }
    }, [isSuccessUpdate, isSuccessCreate]);

    return (
        <form onSubmit={handleSubmit(onClickSaveButton)}
              className={formStyle}>
            <div className={formHeaderStyle}>
                <div className='flex flex-row gap-10'><p>ID: {defaultFormValues.id ? defaultFormValues.id : "не определен"}</p>
                {props.data && <label><input
                    type={"checkbox"} checked={props.data.isAdmin || false}
                    disabled/> Админ</label>}</div>
                <div className="col-start-[-1]">
                    <BackButton/>
                </div>
            </div>
            <div className="grid grid-cols-[repeat(auto-fill,423px)] gap-x-20 gap-y-4 ml-2">
                <ControlledInputField control={control} additionalStyles={'w-full'}
                                      rules={{required: {value: true, message: "Это обязательное поле"}}}
                                      name={"username"} label={"Имя пользователя (login)"}
                                      readonly={props.readonly}
                />
                <ControlledInputField control={control} rules={{
                    pattern: {
                        value: /^(?!\.)(""([^""\r\\]|\\[""\r\\])*""|([-a-z0-9!#$%&'*+/=?^_`{|}~]|(?<!\.)\.)*)(?<!\.)@[a-z0-9][\w\.-]*[a-z0-9]\.[a-z][a-z\.]*[a-z]$/,
                        message: "Введите email в формате post@domen.zone"
                    }
                }}
                                      name={"email"} label={"Почта (e-mail)"}
                                      readonly={props.readonly} additionalStyles={'w-full'}
                />
                <label
                    className="text-sm font-normal"
                >
                    <div className="flex flex-row gap-x-2">
                        <span>Пароль</span>
                        {showPassword && !props.readonly &&
                            <PiEyeLight className="mt-[5px]" onClick={() => setShowPassword(false)}/>}
                        {!showPassword && !props.readonly &&
                            <PiEyeSlashLight className="mt-[5px]" onClick={() => setShowPassword(true)}/>}
                    </div>
                    <ControlledInputField control={control} placeholder={'********'}
                                          type={showPassword ? "text" : "password"} rules={props.data ? {
                        minLength: {
                            value: 8,
                            message: "Длина пароля не менее 8 символов"
                        }
                    } : {
                        required: {
                            value: true,
                            message: "Это обязательное поле"
                        },
                        minLength: {
                            value: 8,
                            message: "Длина пароля не менее 8 символов"
                        }
                    }}
                                          name={"password"}
                                          readonly={props.readonly} additionalStyles={'w-full'}
                    />
                </label>
                <ControlledInputField control={control} rules={{
                    pattern: {
                        value: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/,
                        message: "Введите не менее 6 символов: цифры, тире или пробелы"
                    }
                }}
                                      additionalStyles={'w-full'}
                                      name={"phoneNumber"} label={"Номер телефона"}
                                      readonly={props.readonly}
                />
                <ControlledPaginateAsyncSelect control={control} rules={{}}
                                               label={"Административная роль"} name={"administrativeRole"}
                                               isDisabled={props.readonly}
                                               request={doSearchAdministrativeRolesGuidePage}
                                               width={'100%'}
                                               mapFunc={(data: TablePage<AdministrativeRolesRow>) =>
                                                   data.rows.map(row => ({
                                                       label: row.name,
                                                       value: row.id
                                                   }))}
                />
                <ControlledSelect control={control} isDisabled={props.readonly}
                                  rules={{
                                      required: {
                                          value: true,
                                          message: "Это обязательное поле"
                                      }
                                  }} width={'100%'}
                                  name={"activityStatus"} label={"Статус активности учетной записи"}
                                  options={optionsForActivityStatusSelect}/>
                <ControlledPaginateAsyncSelect control={control}
                                               rules={{required: {value: true, message: "Это обязательное поле"}}}
                                               label={"Физическое лицо"} name={"subject"} isDisabled={props.readonly}
                                               request={doSearchIndividualGuidePage}
                                               width={'100%'}
                                               mapFunc={(data: TablePage<IndividualsRow>) =>
                                                   data.rows.map(row => ({
                                                       label: row.fullName,
                                                       value: row.id
                                                   }))}
                />
                <ControlledInputField control={control} rules={{}}
                                      name={"comment"} label={"Комментарий"}
                                      readonly={props.readonly} additionalStyles={'w-full'}
                />
            </div>
            <SeparatingLine forSeparatingButtons={true}/>
            <div className={formButtonGroupStyle}>
                {!props.readonly &&
                    <Button buttonName={"Отменить"} onClickButton={() => navigate(-1)}/>}
                {!props.readonly && isDirty &&
                    <Button buttonName={"Сохранить"}
                            onClickButton={handleSubmit(onClickSaveButton)}/>}
                {props.readonly &&
                    <Button buttonName={"Редактировать"} onClickButton={() => {
                        navigate(`/user/edit?id=${props.data!.id}`)
                    }}/>}
            </div>
        </form>
    )
}
