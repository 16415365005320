import React from "react"
import {Link, useLocation} from "react-router-dom";
import {useHasAuthority} from "../../../hooks/TokensHooks";
import {SystemRole} from "../../../model/administrative-roles/AdministrativeRole";
import {cx} from "@emotion/css";
import { styles } from "./NavBarLink.styles";

interface NavBarLinkProps {
    to: string
    position: string
    name: string
    isActive?: boolean
    authority?: string
}

export function NavBarLink(props: NavBarLinkProps) {
    const location = useLocation()
    const requiredAuthority = props.authority ? props.authority : SystemRole.ROLE_ADMIN
    const hasAuthority = useHasAuthority(requiredAuthority)

    function getIsActive() {
        const pathSegments = props.to.split('/');
        const basePath = `/${pathSegments[1]}`;
        const subPath = pathSegments[2];

        let isActive: boolean;

        if (basePath === '/production-order' && subPath === 'execution') {
            isActive = location.pathname.startsWith(`/production-order/execution`);
        } else if (basePath === '/production-order' && subPath === 'archive') {
            isActive = location.pathname.startsWith(`/production-order/archive`);
        } else if (basePath === '/production-order' && subPath === 'analysis-execution-by-time') {
            isActive = location.pathname.startsWith(`/production-order/analysis-execution-by-time`);
        } else {
            isActive = location.pathname.startsWith(basePath) && !location.pathname.includes('execution') && !location.pathname.includes('archive');
        }

        return isActive;
    }

    const isActive = getIsActive();

    if (!hasAuthority) {
        return null;
    }

    if (props.isActive || props.isActive === undefined) {
        return (
            <Link
                to={props.to}
                className={cx(`font-light text-left flex items-center pr-1 py-2 ${props.position}`, {[styles.shadow()]: isActive, [styles.default()]: !isActive})}
            >
                <span className="max-w-[275px]">{props.name}</span>
            </Link>
        )
    } else {
        return (
            <button
                className={`font-light text-gray-400 text-left cursor-not-allowed flex items-center py-1.5 ${props.position}`}
            >
                <span className="max-w-[275px]">{props.name}</span>
            </button>
        )
    }
}