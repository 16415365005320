import React, {useEffect, useState} from "react";
import {ProductionOrderExecutionForm} from "../../components/production-order-execution/ProductionOrderExecutionForm";
import {useSearchParams} from "react-router-dom";
import {
    useGetPositionsForProductionOrderExecutionQuery,
    useGetTasksForProductionOrderExecutionQuery
} from "../../store/backend-api/backend.api";
import {scrollStyle} from "../../utils/TailwindStyles";

export function ProductionOrderExecutionFormPage() {
    const [searchParams] = useSearchParams()
    const id = Number(searchParams.get("id"))
    const tabId = searchParams.get("tab") ? Number(searchParams.get("tab")) : null
    const {data: positions, isSuccess: isSuccessGetPositions} = useGetPositionsForProductionOrderExecutionQuery(id)
    const {data: tasks, isSuccess: isSuccessGetTasks} = useGetTasksForProductionOrderExecutionQuery(id)
    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey((prevState) => prevState + 1)
    }, [positions, tasks])

    return (<div key={key} className={scrollStyle}>
        {isSuccessGetPositions && isSuccessGetTasks && <ProductionOrderExecutionForm positions={positions} tasks={tasks} tabId={tabId}/>}
    </div>)
}