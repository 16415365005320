import React, {useEffect} from "react";
import {ProductionOrderJournalTable} from "../../components/production-order/table/ProductionOrderJournalTable";
import {
    useLazyGetAdministrativeAndFunctionalZoneQuery,
    useLazyGetProductionStageQuery, useLazySearchAdministrativeAndFunctionalZoneGuidePageQuery,
    useLazySearchProductionStagesGuidePageQuery,
    useSearchProductionOrderJournalPageQuery
} from "../../store/backend-api/backend.api";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {useAppSelector} from "../../hooks/useAppSelector";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {GuideTopBox} from "../../components/ui/table-components/GuideTopBox";
import {useFilterNodesActions} from "../../hooks/useMenuFiltersActions";
import {productionOrderStatusToString} from "../../data/ProductionOrderData";
import {useDataActions} from "../../hooks/useDataActions";
import {
    DEFAULT_ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER,
    DEFAULT_PRODUCTION_ORDER_JOURNAL_FILTER
} from "../../model/filters/DefaultFilters";
import {MultiSelect} from "../../components/ui/form-components/standalone/MultiSelect";
import {OptionType} from "../../model/option-type/OptionType";
import {ProductionOrderStatus} from "../../model/production-order/ProductionOrder";
import {tablePageStyle} from "../../utils/TailwindStyles";
import {SeparatedDatePicker} from "../../components/ui/form-components/standalone/SeparatedDatePicker";
import {PaginateAsyncMultiSelect} from "../../components/ui/form-components/standalone/PaginateAsyncMultiSelect";
import {UpdateFilter} from "../../store/slices/filters.slice";
import {TablePage} from "../../model/utils/TablePage";
import {ProductionStage} from "../../model/production-stage/ProductionStage";
import {NewPaginateAsyncSelect} from "../../components/ui/form-components/standalone/NewPaginateAsyncSelect";
import {
    AdministrativeAndFunctionalZoneGuideRow
} from "../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuideRow";
import {
    AdministrativeAndFunctionalZonePurpose
} from "../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZone";
import {Permission} from "../../model/administrative-roles/AdministrativeRole";
import {RadioGroup} from "../../components/ui/form-components/standalone/RadioGroup";

export function ProductionOrderDocumentJournal() {
    const [doSearchProductionStagesGuidePage] = useLazySearchProductionStagesGuidePageQuery();
    const [doGetProductionStage] = useLazyGetProductionStageQuery();
    const [doGetAdministrativeAndFunctionalZone] = useLazyGetAdministrativeAndFunctionalZoneQuery();
    const [doSearchAdministrativeAndFunctionalZoneGuidePage] = useLazySearchAdministrativeAndFunctionalZoneGuidePageQuery()

    const {updateFilterAndClearData} = useFiltersActions()
    const {addFiltersToMenu} = useFilterNodesActions()
    const {addData} = useDataActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER].filter;

    const data = useAppSelector(state => state.data.data[TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER])

    const {data: tablePage} = useSearchProductionOrderJournalPageQuery(filter)

    const selectOptions = Array.from(productionOrderStatusToString.keys()).map(key => ({
        label: productionOrderStatusToString.get(key)!,
        value: key
    }))

    async function getProductionStages(productionStagesId: number[]) {
        const productionStagesOptions: OptionType<number>[] = []
        for (const productionStageId of productionStagesId) {
            const productionStage = (await doGetProductionStage(productionStageId).unwrap())
            if (productionStage) {
                productionStagesOptions.push({label: productionStage.name, value: productionStage.id})
            }
        }
        if (productionStagesOptions.length !== 0) {
            return productionStagesOptions
        }
        return undefined
    }

    async function getAdministrativeAndFunctionalZone(productionLineId: number | null, defaultOptionsForProductionStages: OptionType<number>[] | undefined) {
        let defaultOption;
        if (productionLineId) {
            const productionLine = (await doGetAdministrativeAndFunctionalZone(productionLineId).unwrap())
            defaultOption = {label: productionLine.name!, value: productionLine.id!} as OptionType<number>;
        } else {
            defaultOption = undefined;
        }
        return {
            defaultOptionForAdministrativeAndFunctionalZone: defaultOption,
            defaultOptionsForProductionStages: defaultOptionsForProductionStages
        };
    }

    useEffect(() => {
        getProductionStages(filter.productionStageIds)
            .then(defaultOptionsForProductionStages =>
                getAdministrativeAndFunctionalZone(filter.productionLineId, defaultOptionsForProductionStages)
                    .then(object =>
                        addFiltersToMenu({
                            key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER,
                            value: [
                                {
                                    filterName: "Производственная линия",
                                    filter: <NewPaginateAsyncSelect onChange={(option: OptionType<number> | null) => {
                                        const newFilter: UpdateFilter = {
                                            key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER,
                                            value: {...filter, productionLineId: option ? option.value : null}
                                        }
                                        updateFilterAndClearData(newFilter)
                                    }} defaultOption={object.defaultOptionForAdministrativeAndFunctionalZone}
                                                                    width={"270px"}
                                                                    request={doSearchAdministrativeAndFunctionalZoneGuidePage}
                                                                    mapFunc={(data: TablePage<AdministrativeAndFunctionalZoneGuideRow>) =>
                                                                        data.rows.map(row => ({
                                                                            label: row.name,
                                                                            value: row.id
                                                                        }))
                                                                    }
                                                                    filter={{
                                                                        ...DEFAULT_ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER,
                                                                        purpose: AdministrativeAndFunctionalZonePurpose.PRODUCTION_LINE
                                                                    }}/>
                                },
                                {
                                    filterName: "Статус заказа",
                                    filter: <MultiSelect options={selectOptions}
                                                         onChange={(options: readonly OptionType<ProductionOrderStatus>[] | null) => {
                                                             updateFilterAndClearData({
                                                                 key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER,
                                                                 value: {
                                                                     ...filter,
                                                                     status: options ? options.map(option => option.value) : []
                                                                 }
                                                             })
                                                         }}
                                                         width={"270px"}
                                                         defaultOptions={filter.status.map(status => ({
                                                             label: productionOrderStatusToString.get(status)!,
                                                             value: status
                                                         }))}
                                    />
                                }, {
                                    filterName: "Этапы производства",
                                    filter: <PaginateAsyncMultiSelect
                                        onChange={(options: OptionType<number>[] | null) => {
                                            const newFilter: UpdateFilter = {
                                                key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER,
                                                value: {
                                                    ...filter,
                                                    productionStageIds: options ? options.map(option => option.value) : []
                                                }
                                            }
                                            updateFilterAndClearData(newFilter)
                                        }} defaultOptions={object.defaultOptionsForProductionStages} width={"270px"}
                                        request={doSearchProductionStagesGuidePage}
                                        mapFunc={(data: TablePage<ProductionStage>) =>
                                            data.rows.map(row => ({label: row.name, value: row.id}))
                                        }/>
                                }, {
                                    filterName: "Требуется завершить",
                                    filter: <SeparatedDatePicker
                                        onChange={(startDate: Date | null, endDate: Date | null) => {
                                            updateFilterAndClearData({
                                                key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER,
                                                value: {
                                                    ...filter,
                                                    planningCompletionDateFrom: startDate ? startDate.toISOString().slice(0, 10) : null,
                                                    planningCompletionDateTo: endDate ? endDate.toISOString().slice(0, 10) : null
                                                }
                                            })
                                        }}
                                        startDate={filter.planningCompletionDateFrom ? new Date(filter.planningCompletionDateFrom) : undefined}
                                        endDate={filter.planningCompletionDateTo ? new Date(filter.planningCompletionDateTo) : undefined}
                                    />
                                }, {
                                    filterName: "Завершён",
                                    filter: <SeparatedDatePicker
                                        onChange={(startDate: Date | null, endDate: Date | null) => {
                                            updateFilterAndClearData({
                                                key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER,
                                                value: {
                                                    ...filter,
                                                    completionDateFrom: startDate ? startDate.toISOString().slice(0, 10) : null,
                                                    completionDateTo: endDate ? endDate.toISOString().slice(0, 10) : null
                                                }
                                            })
                                        }}
                                        startDate={filter.completionDateFrom ? new Date(filter.completionDateFrom) : undefined}
                                        endDate={filter.completionDateTo ? new Date(filter.completionDateTo) : undefined}/>
                                }, {
                                    filterName: 'Отображаемые документы',
                                    filter: <div className='ml-2'><RadioGroup initialValue={filter.isDeleted?.toString() || 'false'} options={[{
                                        label: 'Активные',
                                        value: 'false'
                                    }, {
                                        label: 'Из корзины',
                                        value: 'true'
                                    }]} onChange={(event) => {
                                        updateFilterAndClearData({
                                            key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER,
                                            value: {
                                                ...filter,
                                                isDeleted: event.target.value === 'true',
                                            }
                                        });
                                    }}/></div>
                                },
                            ]
                        })))
    }, [filter.status, filter.isDeleted, filter.planningCompletionDateFrom, filter.planningCompletionDateTo, filter.completionDateFrom, filter.completionDateTo, filter.productionStageIds, filter.productionLineId]);

    useEffect(() => {
        if (tablePage) {
            if (!data || data.rows.length < (tablePage.pageNumber * DEFAULT_PRODUCTION_ORDER_JOURNAL_FILTER.pageSize) + 1) {
                addData({key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER, data: tablePage})
            }
        }

    }, [tablePage]);

    return (<>
        <div className={tablePageStyle}>
            <GuideTopBox tableFilterKey={TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER}
                /*createNavigationURL={"/production-order/new"}*/
                         searchInfo={"Поиск по № Work и основной продукции."}/>
            {data && <ProductionOrderJournalTable data={data}/>}
        </div>
    </>)
}