import React, {useEffect} from "react";
import {UsersTable} from "../../components/users/UsersTable";
import {
    useLazyGetAdministrativeRoleQuery,
    useLazySearchAdministrativeRolesGuidePageQuery,
    useSearchUserGuidePageQuery
} from "../../store/backend-api/backend.api";
import {GuideTopBox} from "../../components/ui/table-components/GuideTopBox";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {useFilterNodesActions} from "../../hooks/useMenuFiltersActions";
import {TablePage} from "../../model/utils/TablePage";
import {OptionType} from "../../model/option-type/OptionType";
import {SeparatedDatePicker} from "../../components/ui/form-components/standalone/SeparatedDatePicker";
import {ActivityStatus} from "../../model/users/User";
import {activityStatusesToString} from "../../data/UserFormData";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {useDataActions} from "../../hooks/useDataActions";
import {DEFAULT_USER_GUIDE_FILTER} from "../../model/filters/DefaultFilters";
import {NewSelect} from "../../components/ui/form-components/standalone/NewSelect";
import {UpdateFilter} from "../../store/slices/filters.slice";
import {PaginateAsyncMultiSelect} from "../../components/ui/form-components/standalone/PaginateAsyncMultiSelect";
import {AdministrativeRolesRow} from "../../model/administrative-roles/AdministrativeRole";
import {tablePageStyle} from "../../utils/TailwindStyles";

export function UserGuide() {
    const {updateFilterAndClearData} = useFiltersActions()
    const {addFiltersToMenu} = useFilterNodesActions()
    const {addData} = useDataActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.USER_GUIDE_FILTER].filter;

    const data = useAppSelector(state => state.data.data[TableFilterKeys.USER_GUIDE_FILTER])

    const [doGetAdministrativeRole] = useLazyGetAdministrativeRoleQuery()
    const [doSearchAdministrativeRolesGuidePage] = useLazySearchAdministrativeRolesGuidePageQuery()
    const {data: tablePage} = useSearchUserGuidePageQuery(filter)

    const optionsForActivityStatusSelect: OptionType<ActivityStatus>[] = Array.from(activityStatusesToString.keys()).map(key => ({
        label: activityStatusesToString.get(ActivityStatus[key])!,
        value: key
    }))

    async function getAdministrativeRoles(administrativeRolesId: number[]) {
        const rolesOptions: OptionType<number>[] = []
        for (const roleId of administrativeRolesId) {
            const role = (await doGetAdministrativeRole(roleId).unwrap())
            if (role) {
                rolesOptions.push({label: role.name, value: role.id})
            }
        }
        if (rolesOptions.length !== 0) {
            return rolesOptions
        }
        return undefined
    }

    useEffect(() => {
        if (tablePage) {
            if (!data || data.rows.length < (tablePage.pageNumber * DEFAULT_USER_GUIDE_FILTER.pageSize) + 1) {
                addData({key: TableFilterKeys.USER_GUIDE_FILTER, data: tablePage})
            }
        }

    }, [tablePage]);

    useEffect(() => {
        getAdministrativeRoles(filter.role)
            .then(defaultOptions => {
                addFiltersToMenu({
                    key: TableFilterKeys.USER_GUIDE_FILTER,
                    value: [{
                        filterName: "Регистрация",
                        filter: <SeparatedDatePicker
                            onChange={(startDate: Date | null, endDate: Date | null) => {
                                updateFilterAndClearData({
                                    key: TableFilterKeys.USER_GUIDE_FILTER,
                                    value: {
                                        ...filter,
                                        registrationFrom: startDate ? startDate.toISOString() : null,
                                        registrationTo: endDate ? endDate.toISOString() : null
                                    }
                                })
                            }}
                            startDate={filter.registrationFrom ? new Date(filter.registrationFrom) : undefined}
                            endDate={filter.registrationTo ? new Date(filter.registrationTo) : undefined}
                        />
                    }, {
                        filterName: "Последняя активность",
                        filter: <SeparatedDatePicker
                            onChange={(startDate: Date | null, endDate: Date | null) => {
                                updateFilterAndClearData({
                                    key: TableFilterKeys.USER_GUIDE_FILTER,
                                    value: {
                                        ...filter,
                                        lastActivityFrom: startDate ? startDate.toISOString() : null,
                                        lastActivityTo: endDate ? endDate.toISOString() : null
                                    }
                                })
                            }}
                            startDate={filter.lastActivityFrom ? new Date(filter.lastActivityFrom) : undefined}
                            endDate={filter.lastActivityTo ? new Date(filter.lastActivityTo) : undefined}/>
                    }, {
                        filterName: "Административная роль",
                        filter: <PaginateAsyncMultiSelect onChange={(options: OptionType<number>[] | null) => {
                            const newFilter: UpdateFilter = {
                                key: TableFilterKeys.USER_GUIDE_FILTER,
                                value: {...filter, role: options ? options.map(option => option.value) : []}
                            }
                            updateFilterAndClearData(newFilter)
                        }} defaultOptions={defaultOptions} width={"270px"}
                                                          request={doSearchAdministrativeRolesGuidePage}
                                                          mapFunc={(data: TablePage<AdministrativeRolesRow>) =>
                                                              data.rows.map(row => ({label: row.name, value: row.id}))
                                                          }/>
                    },
                        {
                            filterName: "Активность",
                            filter: <NewSelect
                                options={optionsForActivityStatusSelect}
                                onChange={(option: OptionType<ActivityStatus> | null) => {
                                    updateFilterAndClearData({
                                        key: TableFilterKeys.USER_GUIDE_FILTER,
                                        value: {
                                            ...filter,
                                            status: option ? option.value : null
                                        }
                                    })
                                }}
                                width={"270px"}
                                defaultOption={filter.status ? {
                                    label: activityStatusesToString.get(filter.status)!,
                                    value: filter.status
                                } : undefined}
                            />
                        }
                    ]
                })
            })

    }, [filter.registrationFrom, filter.registrationTo, filter.lastActivityFrom, filter.lastActivityTo, filter.role, filter.status])

    return (
        <div className={tablePageStyle}>
            <GuideTopBox tableFilterKey={TableFilterKeys.USER_GUIDE_FILTER}
                         createNavigationURL={'/user/new'}
                         searchInfo={"Поиск по имени пользователя и физическому лицу."}/>
            {data && <UsersTable data={data}/>}
        </div>
    )
}