import React from "react";
import {Control, Controller} from "react-hook-form";

interface InputProps {
    control: Control<any>
    readonly?: boolean
    rules: {}
    name: string
    label?: string
    additionalStyles?: string
    defaultValue?: string | number
    placeholder?: string
    type?: string
}

export function ControlledInputFieldForTable(props: InputProps) {
    return (
        <Controller control={props.control} name={props.name} rules={props.rules} defaultValue={props.defaultValue === undefined ? "" : props.defaultValue}
                    render={({field: {onChange, value}, fieldState: {error}}) =>
                        <>
                            <label
                                className="text-sm font-normal"
                            > {props.label}
                                <input
                                    type={props.type ? props.type : "text"}
                                    disabled={props.readonly !== undefined ? props.readonly : false}
                                    className={`${props.additionalStyles} block bg-white px-3 mt-[2px] h-[30px] mb-[1px] font-light text-sm`}
                                    onChange={onChange}
                                    value={value}
                                    placeholder={props.placeholder !== undefined ? props.placeholder : "Не заполнено"}
                                    readOnly={props.readonly !== undefined ? props.readonly : false}
                                />
                                {error && <p className="border-t text-sm font-normal text-red-400">{error.message}</p>}
                            </label>

                        </>
                    }/>
    )
}