import React from "react";
import {Control, Controller} from "react-hook-form";
import DatePicker, {registerLocale} from "react-datepicker";
import ru from "date-fns/locale/ru";

interface DatePickerProps {
    control: Control<any>
    readonly?: boolean
    rules: {}
    name: string
    label?: string
    additionalStyles?: string
    defaultValue?: string | number
    placeholder?: string
}
registerLocale("ru", ru)
export function ControlledDatePicker(props: DatePickerProps) {
    const styles: string = props.readonly ? `border-gray-300` : `border-gray-400/[.80] focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600`
    return (
        <Controller control={props.control} name={props.name} rules={props.rules} defaultValue={props.defaultValue === undefined ? "" : props.defaultValue}
                    render={({field: {onChange, value}, fieldState: {error}}) =>
                        <>
                            <label
                                className="text-sm font-normal"
                            > <p>{props.label}</p>
                                <DatePicker
                                    locale="ru"
                                    dateFormat="dd.MM.yyyy"
                                    selected={value}
                                    readOnly={props.readonly}
                                    disabled={props.readonly}
                                    onChange={onChange}
                                    placeholderText={props.placeholder ? props.placeholder : "Не заполнено"}
                                    className={`${props.additionalStyles} ${styles} block bg-white border-2 rounded-md px-3 mt-[2px] h-9 mb-[1px] font-light text-sm`}
                                />
                                {error && <p className="text-sm font-normal text-red-400">{error.message}</p>}
                            </label>
                        </>}/>
    )
}