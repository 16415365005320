import React from "react";
import {Table, TableColumn} from "../tables/Table";
import {productionOrderStatusToString} from "../../data/ProductionOrderData";
import {useNavigate} from "react-router-dom";
import {TablePage} from "../../model/utils/TablePage";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {ProductionStage} from "../../model/production-stage/ProductionStage";
import {ProductionOrderAnalysisByTime} from "../../model/production-order-analysis-by-time/ProductionOrderAnalysisByTime";

interface TableProps {
    data: TablePage<ProductionOrderAnalysisByTime>,
    productionStages: ProductionStage[]
}

export function ProductionOrderAnalysisByTimeTable(props: TableProps) {
    const {updateFilterPage} = useFiltersActions()
    const navigate = useNavigate()

    const numberFromWorkColumn: TableColumn<ProductionOrderAnalysisByTime> = {
        columnName: '№ Work',
        extractCellContent: row => row.numberFromWork
    }

    const mainProductColumn: TableColumn<ProductionOrderAnalysisByTime> = {
        columnName: 'Основная продукция',
        extractCellContent: row => row.mainProduct ? `${row.mainProduct?.nomenclature?.name}, ${row.mainProduct?.nomenclature?.measurementUnit?.name || "–"}` : "–"
    }

    const additionalEquipmentColumn: TableColumn<ProductionOrderAnalysisByTime> = {
        columnName: 'Доп. комплектация',
        title: 'Дополнительная комплектация',
        displayAtList: true,
        additionalStyles: 'max-w-[300px] w-max',
        extractCellContent: row => row.additionalEquipment.length === 0 ? "–" : row.additionalEquipment.map(equipment => equipment.nomenclature.name)
    }

    const totalTimeSpentColumn: TableColumn<ProductionOrderAnalysisByTime> = {
        columnName: 'Затраченное время',
        extractCellContent: row => row.totalTimeSpent
    }

    const productionOrderStatusColumn: TableColumn<ProductionOrderAnalysisByTime> = {
        columnName: 'Статус заказа',
        extractCellContent: row => productionOrderStatusToString.get(row.productionOrderStatus)
    }

    const progressColumns: TableColumn<ProductionOrderAnalysisByTime>[] = [...props.productionStages].sort((a, b) => a.id - b.id).map(productionStage => ({
        columnName: productionStage.name,
        additionalStyles: 'w-16 truncate block',
        extractCellContent: (row: ProductionOrderAnalysisByTime) => <span className='whitespace-nowrap'>{row.progress.find(stageProgress => stageProgress.productionStage.id === productionStage.id)?.timeSpent  ?? '–'}</span>
    }))

    function actionOnRowClick(row: ProductionOrderAnalysisByTime) {
        navigate(`/production-order?number=${row.documentNumber}`)
    }

    return (
        <Table<ProductionOrderAnalysisByTime>
            tableColumns={[numberFromWorkColumn, mainProductColumn, additionalEquipmentColumn, totalTimeSpentColumn, productionOrderStatusColumn, ...progressColumns]}
            rows={props.data.rows}
            onRowClick={actionOnRowClick}
            tableFilterKey={TableFilterKeys.PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => {
                    updateFilterPage({key: TableFilterKeys.PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER, value: newPageNumber})
                }
            }}
        />
    )
}