import {GuideFilter} from "./GuideFilter";
import {UserGuideFilter} from "../users/UserGuideFilter";
import {NomenclatureGuideFilter} from "../nomenclature/NomenclatureGuideFilter";
import {ProductionOrderExecutionJournalFilter} from "../production-order-execution/ProductionOrderExecution";
import {
    AdministrativeAndFunctionalZoneGuideFilter
} from "../administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuideFilter";
import {IndividualsGuideFilter} from "../individuals/Individuals";
import {TechnologicalProcessChartGuideFilter} from "../technological-process-chart/TechnologicalProcessChart";
import {ProductionStageGuideFilter} from "../production-stage/ProductionStage";
import {AdministrativeRoleGuideFilter} from "../administrative-roles/AdministrativeRole";
import {TechnologicalOperationGuideFilter} from "../technological-operation/TechnologicalOperationGuideFilter";
import {ProductionOrderJournalFilter} from "../production-order/ProductionOrder";
import {MeasurementGuideFilter} from "../measurement/MeasurementGuideFilter";
import {MeasurementOkeiGuideFilter} from "../measurement/MeasurementOkeiGuideFilter";
import {
    ProductionOrderAnalysisByTimeReportFilter
} from "../production-order-analysis-by-time/ProductionOrderAnalysisByTime";

export const DEFAULT_GUIDE_FILTER: GuideFilter = {
    pageNumber: 0,
    pageSize: 30,
    search: null,
    sortOrder: null
}

export const DEFAULT_USER_GUIDE_FILTER: UserGuideFilter = {
    pageNumber: 0,
    pageSize: 30,
    search: null,
    sortOrder: null,
    sortedField: null,
    registrationFrom: null,
    registrationTo: null,
    lastActivityFrom: null,
    lastActivityTo: null,
    status: null,
    role: [],
};

export const DEFAULT_NOMENCLATURE_GUIDE_FILTER: NomenclatureGuideFilter = {
    pageNumber: 0,
    pageSize: 30,
    search: null,
    sortOrder: null,
    sortedField: null,
    productionNomenclatureClassifier: [],
};

export const DEFAULT_PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER: ProductionOrderExecutionJournalFilter = {
    pageNumber: 0,
    pageSize: 30,
    search: null,
    sortOrder: null,
    productionLineId: null
};

export const DEFAULT_ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER: AdministrativeAndFunctionalZoneGuideFilter = {
    pageNumber: 0,
    pageSize: 30,
    search: null,
    sortOrder: null,
    purpose: null,
    sortedField: null,
};

export const DEFAULT_INDIVIDUALS_GUIDE_FILTER: IndividualsGuideFilter = {
    pageNumber: 0,
    pageSize: 30,
    search: null,
    sortOrder: null,
    gender: null,
    isUser: null,
    sortedField: null,
};

export const DEFAULT_ADMINISTRATIVE_ROLE_GUIDE_FILTER: AdministrativeRoleGuideFilter = {
    pageNumber: 0,
    pageSize: 30,
    search: null,
    sortOrder: null
};

export const DEFAULT_TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER: TechnologicalProcessChartGuideFilter = {
    pageNumber: 0,
    pageSize: 30,
    search: null,
    sortOrder: null,
    productionNomenclatureClassifier: []
};

export const DEFAULT_PRODUCTION_STAGE_GUIDE_FILTER: ProductionStageGuideFilter = {
    pageNumber: 0,
    pageSize: 30,
    search: null,
    sortOrder: null
};

export const DEFAULT_TECHNOLOGICAL_OPERATION_GUIDE_FILTER: TechnologicalOperationGuideFilter = {
    pageNumber: 0,
    pageSize: 30,
    search: null,
    sortOrder: null,
    sortedField: null
};

export const DEFAULT_PRODUCTION_ORDER_JOURNAL_FILTER: ProductionOrderJournalFilter = {
    additionalItemsSearch: '',
    completionDateFrom: null,
    completionDateTo: null,
    externalIdSearch: '',
    planningCompletionDateFrom: null,
    planningCompletionDateTo: null,
    productionLineId: null,
    productionStageIds: [],
    sortedField: null,
    targetItemSearch: '',
    pageNumber: 0,
    pageSize: 15,
    search: null,
    sortOrder: null,
    status: [],
    isDeleted: false,
};

export const DEFAULT_PRODUCTION_ORDER_ARCHIVE_FILTER: ProductionOrderJournalFilter = {
    additionalItemsSearch: null,
    completionDateFrom: null,
    completionDateTo: null,
    externalIdSearch: null,
    planningCompletionDateFrom: null,
    planningCompletionDateTo: null,
    productionLineId: null,
    productionStageIds: [],
    sortedField: null,
    targetItemSearch: null,
    pageNumber: 0,
    pageSize: 15,
    search: null,
    sortOrder: null,
    status: [],
    isArchived: true
};

export const DEFAULT_PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER: ProductionOrderAnalysisByTimeReportFilter = {
    pageNumber: 0,
    pageSize: 15,
    search: null,
    sortOrder: null,
    status: [],
    isArchived: false
};

export const DEFAULT_MEASUREMENT_UNIT_GUIDE_FILTER: MeasurementGuideFilter = {
    pageNumber: 0,
    pageSize: 30,
    search: null,
    sortOrder: null,
    sortedField: null
};

export const DEFAULT_MEASUREMENT_OKEI_GUIDE_FILTER: MeasurementOkeiGuideFilter = {
    pageNumber: 0,
    pageSize: 30,
    search: null,
    sortOrder: null,
    sortedField: null
};


