import React from "react";

interface TableProps {
    creationTime: string,
    addedInLineTime: string,
    productionStartTime: string,
    completionTime: string | null
}

export function ProductionOrderExecutionTable(props: TableProps) {

    return (
        <table className='w-fit mx-2'>
            <tbody>
            <tr>
                <td className="border border-black text-sm font-light px-1.5">Выбор технологических карт</td>
                <td className="border border-black text-sm font-light px-1.5">{props.creationTime || "–"}</td>
            </tr>
            <tr>
                <td className="border border-black text-sm font-light px-1.5">В производственной очереди</td>
                <td className="border border-black text-sm font-light px-1.5">{props.addedInLineTime || "–"}</td>
            </tr>
            <tr>
                <td className="border border-black text-sm font-light px-1.5">В производстве</td>
                <td className="border border-black text-sm font-light px-1.5">{props.productionStartTime || "–"}</td>
            </tr>
            <tr>
                <td className="border border-black text-sm font-light px-1.5">Завершён</td>
                <td className="border border-black text-sm font-light px-1.5">{props.completionTime || "–"}</td>
            </tr>
            </tbody>
        </table>
    )
}