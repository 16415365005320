import React, {useEffect} from "react";
import {Controller, SubmitHandler, useFieldArray, useForm, useWatch} from "react-hook-form";
import {BackButton} from "../ui/buttons/BackButton";
import {useNavigate} from "react-router-dom";
import {
    AdministrativeRole,
    CreatingOrUpdatingAdministrativeRole,
    Permission
} from "../../model/administrative-roles/AdministrativeRole";
import {ControlledInputField} from "../ui/form-components/controlled/ControlledInputField";
import {
    createDefaultValuesForPermissionArray,
    createDefaultValuesForProductionStageArray,
    onClickDeleteAdministrativeRoleButton
} from "./AdditionalFunctionsForAdministrativeRoleFormTemplate";
import {objectsWithCustomizableAccessToString} from "../../data/AdministrativeRolesData";
import {ControlledMultiSelectUseFieldArray} from "../ui/form-components/controlled/ControlledMultiSelectUseFieldArray";
import {ProductionStage} from "../../model/production-stage/ProductionStage";
import {Button} from "../ui/buttons/Button";
import {
    useCreateAdministrativeRoleMutation,
    useDeleteAdministrativeRoleMutation,
    useUpdateAdministrativeRoleMutation
} from "../../store/backend-api/backend.api";
import {SeparatingLine} from "../ui/common/SeparatingLine";
import {formButtonGroupStyle, formHeaderStyle, formStyle} from "../../utils/TailwindStyles";

interface FormProps {
    data?: AdministrativeRole,
    readonly?: boolean,
    productionStages: ProductionStage[]
}

interface FormFields {
    id: number | null,
    name: string,
    permission: {
        objectName: string,
        access: Permission
    }[],
    multiSelectWithCheckboxes: MultiSelectWithCheckboxes[]
}

interface MultiSelectWithCheckboxes {
    checkboxValue: any,
    checkboxLabel: string,
    checked: boolean
}

export function AdministrativeRoleFormTemplate(props: FormProps) {
    const [doCreateAdministrativeRole, {
        data: createdAdministrativeRoleId,
        isSuccess: isSuccessCreate
    }] = useCreateAdministrativeRoleMutation()
    const [doUpdateAdministrativeRole, {isSuccess: isSuccessUpdate}] = useUpdateAdministrativeRoleMutation()
    const [doDeleteAdministrativeRole, {isSuccess: isSuccessDelete}] = useDeleteAdministrativeRoleMutation()
    const navigate = useNavigate()
    const defaultFormValues: FormFields = props.data ? {
        id: props.data.id,
        name: props.data.name,
        permission: createDefaultValuesForPermissionArray(props.data.permissions),
        multiSelectWithCheckboxes: createDefaultValuesForProductionStageArray(props.productionStages, props.data.availableProductionStages)
    } : {
        id: null,
        name: "",
        permission: createDefaultValuesForPermissionArray([]),
        multiSelectWithCheckboxes: createDefaultValuesForProductionStageArray(props.productionStages, [])
    }
    const {control, handleSubmit, setValue, formState: {isDirty}} = useForm<FormFields>({
        mode: "onChange",
        defaultValues: defaultFormValues
    })
    const {fields} = useFieldArray({
        name: "permission",
        control: control
    })
    const currentFormValue = useWatch({control})
    const onClickSaveButton: SubmitHandler<FormFields> = data => {
        const administrativeRole: CreatingOrUpdatingAdministrativeRole = {
            name: data.name,
            permissions: data.permission.filter(object => object.access !== Permission.NO_ACCESS).map(permission => permission.access),
            availableProductionStages: data.multiSelectWithCheckboxes.filter(stage => stage.checked).map(stage => stage.checkboxValue)
        }
        if (props.data && isDirty) {
            doUpdateAdministrativeRole({id: props.data.id, body: administrativeRole})
        } else if (!props.data) {
            doCreateAdministrativeRole(administrativeRole)
        }
    }

    useEffect(() => {
        if (isSuccessUpdate) {
            navigate(`/administrative-roles?id=${props.data!.id}`)
        }
        if (isSuccessCreate) {
            navigate(`/administrative-roles?id=${createdAdministrativeRoleId}`)
        }
        if (isSuccessDelete) {
            navigate('administrative-roles/guide')
        }
    }, [isSuccessUpdate, isSuccessDelete, isSuccessCreate, createdAdministrativeRoleId]);

    return (
        <form onSubmit={handleSubmit(onClickSaveButton)} className={formStyle}>
            <div className={formHeaderStyle}>
                <p>ID: {defaultFormValues.id !== null ? defaultFormValues.id : "не определен"}</p>
                <BackButton/>
            </div>
            <div className='ml-2'><ControlledInputField control={control} rules={{
                required: {
                    value: true,
                    message: "Это обязательное поле"
                }
            }}
                                                        name={"name"} label={"Наименование роли"}
                                                        readonly={props.readonly !== undefined && props.readonly}
                                                        additionalStyles={"w-[423px]"}/></div>
            <SeparatingLine label={"Настройка прав доступа"}/>
            <table className='ml-2'>
                <thead>
                <tr>
                    <td className="border border-black text-sm font-normal text-center px-1">Наименование объекта</td>
                    <td className="border border-black text-sm font-normal text-center px-1">Нет доступа</td>
                    <td className="border border-black text-sm font-normal text-center px-1">Доступен просмотр</td>
                    <td className="border border-black text-sm font-normal text-center px-1">Полный доступ</td>
                </tr>
                </thead>
                <tbody>
                {fields.map((field, index) => <tr key={field.id}>
                    <td className="border border-black text-sm font-light px-1 whitespace-nowrap">{objectsWithCustomizableAccessToString.get(field.objectName)}</td>
                    <Controller control={control} name={`permission.${index}.access`} rules={{}}
                                render={({field: {value, onChange}}) => <>
                                    <td className="border border-black text-center">
                                        <label className="inline-block w-full h-full cursor-pointer"><input
                                            type={"radio"}
                                            value={Permission.NO_ACCESS}
                                            onChange={(event) => onChange(event.target.value)}
                                            checked={value === Permission.NO_ACCESS}
                                            disabled={props.readonly !== undefined && props.readonly}/></label></td>
                                    <td className="border border-black text-center">
                                        <label className="inline-block w-full h-full cursor-pointer"><input
                                            type={"radio"}
                                            value={Permission[`${field.objectName}_VIEW` as keyof typeof Permission]}
                                            onChange={(event) => onChange(event.target.value)}
                                            checked={value === Permission[`${field.objectName}_VIEW` as keyof typeof Permission]}
                                            disabled={props.readonly !== undefined && props.readonly}/></label>
                                    </td>
                                    <td className="border border-black text-center">
                                        <label className="inline-block w-full h-full cursor-pointer"><input
                                            type={"radio"}
                                            value={Permission[`${field.objectName}_MANAGE` as keyof typeof Permission]}
                                            onChange={(event) => onChange(event.target.value)}
                                            checked={value === Permission[`${field.objectName}_MANAGE` as keyof typeof Permission]}
                                            disabled={props.readonly !== undefined && props.readonly}/></label>
                                    </td>
                                </>}/>
                </tr>)}
                </tbody>
            </table>
            <SeparatingLine label={"Выбор этапов производства для пользователя с данной ролью"}/>
            <div className='ml-2'><ControlledMultiSelectUseFieldArray control={control} rules={{}}
                                                                      label={"Доступные этапы производства"}
                                                                      readonly={props.readonly !== undefined && props.readonly}
                                                                      currentArrayValue={currentFormValue.multiSelectWithCheckboxes}
                                                                      setValue={setValue}/></div>
            <SeparatingLine forSeparatingButtons={true}/>
            <div className={formButtonGroupStyle}>
                {props.data && <Button buttonName={"Удалить"}
                                       onClickButton={() => onClickDeleteAdministrativeRoleButton(props.data!.id, doDeleteAdministrativeRole)}/>}
                {!props.readonly &&
                    <Button buttonName={"Сохранить"} onClickButton={handleSubmit(onClickSaveButton)}/>}
                {props.readonly &&
                    <Button buttonName={"Редактировать"} onClickButton={() => {
                        navigate(`/administrative-roles/edit?id=${props.data!.id}`)
                    }}/>}
            </div>
        </form>
    )
}