import React, {useEffect, useMemo, useState} from "react";
import {styles} from './ProductionOrderPopupContent.styles';
import {useAppSelector} from "../../../../../hooks/useAppSelector";
import {TableFilterKeys} from "../../../../../model/filters/TableFiltersDefinitions";
import {debounce} from "lodash";
import {useFiltersActions} from "../../../../../hooks/useFiltersActions";
import {SearchInput} from "../../../../ui/table-components/SearchInput";

interface Props {
    tableFilterKey: TableFilterKeys,
    filterFieldName: string;
}

export const ProductionOrderPopupContent = ({filterFieldName, tableFilterKey}: Props) => {
    const {updateFilterAndClearData} = useFiltersActions();
    const state = useAppSelector(state => state.sessionStorage.filters);
    const filter = state[tableFilterKey].filter;
    // @ts-ignore
    const [search, setSearch] = useState<string>(filter[filterFieldName] ?? '');

    function onSearchInput(text: string | null) {
        setSearch(text || "");
    }

    useEffect(() => {
        // @ts-ignore
        if (search === filter[filterFieldName]) return;
        // @ts-ignore
        setSearch(filter[filterFieldName] || '')
        // @ts-ignore
    }, [filter[filterFieldName], filterFieldName]);

    useEffect(() => {
        // @ts-ignore
        if (search === filter[filterFieldName]) return;
        let timerForSearchDebounce = setTimeout(() => {
            const newFilter = {...filter, pageNumber: 0};
            // @ts-ignore
            newFilter[filterFieldName] = search;
            updateFilterAndClearData({
                key: tableFilterKey,
                value: newFilter,
            })
        }, 2000);
        return () => {
            clearTimeout(timerForSearchDebounce);
        };
    }, [search]);

    return (
        <SearchInput placeholder="Поиск" onChange={onSearchInput} value={search}/>
    );
};