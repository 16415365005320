import React from "react";

interface LineProps {
    label?: string,
    forSeparatingButtons?: boolean
}

export function SeparatingLine(props: LineProps) {
    return (
        <div className="flex flex-row items-center my-2 px-2 min-w-full">
            {props.label && <div className="text-sm font-light text-black/[.70] mr-3">{props.label}</div>}
            <div className={`border-b-[1px] border-slate-400/[.80] border-dotted h-1 grow`}></div>
        </div>
    )
}