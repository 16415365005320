import {useSearchMeasurementUnitGuidePageQuery} from "../../store/backend-api/backend.api";
import {useAppSelector} from "../../hooks/useAppSelector";
import React, {useEffect} from "react";
import {MeasurementUnitTable} from "../../components/measurement-unit/table/MeasurementUnitTable";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {GuideTopBox} from "../../components/ui/table-components/GuideTopBox";
import {DEFAULT_MEASUREMENT_UNIT_GUIDE_FILTER} from "../../model/filters/DefaultFilters";
import {useDataActions} from "../../hooks/useDataActions";
import {tablePageStyle} from "../../utils/TailwindStyles";


export function MeasurementUnitGuide() {
    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER].filter;
    const {addData} = useDataActions()

    const data = useAppSelector(state => state.data.data[TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER])

    const {data: tablePage} = useSearchMeasurementUnitGuidePageQuery(filter)

    useEffect(() => {
        if (tablePage) {
            if (!data || data.rows.length < (tablePage.pageNumber * DEFAULT_MEASUREMENT_UNIT_GUIDE_FILTER.pageSize) + 1) {
                addData({key: TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER, data: tablePage})
            }
        }

    }, [tablePage]);

    return (
        <div className={tablePageStyle}>
            <GuideTopBox tableFilterKey={TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER}
                         createNavigationURL={"/measurement-unit/new"}
                         searchInfo={"Поиск по ID, наименованию, условному обозначению, коду и группе в ОКЕИ."}/>
            {data && <MeasurementUnitTable data={data}/>}
        </div>
    )
}