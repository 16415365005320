import React, {useEffect, useState} from "react";
import {AddButtonText} from "../ui/buttons/AddButtonText";
import ReactModal from "react-modal";
import {FiX} from "react-icons/fi";
import {MaterialOrProductCreateForm} from "./MaterialOrProductCreateForm";
import {NomenclatureFormEdit} from "../nomenclature/form/NomenclatureFormEdit";
import {
    Control,
    Controller,
    FieldArrayWithId,
    useFieldArray,
    UseFormResetField,
    UseFormSetValue,
    useWatch
} from "react-hook-form";
import {ControlledPaginateAsyncSelectForTable} from "../ui/form-components/controlled/ControlledPaginateAsyncSelectForTable";
import {TablePage} from "../../model/utils/TablePage";
import {NomenclatureGuideRow} from "../../model/nomenclature/NomenclatureGuideRow";
import {PiPencilLight, PiTrashLight} from "react-icons/pi";
import {ControlledInputFieldForTable} from "../ui/form-components/controlled/ControlledInputFieldForTable";
import {MeasurementUnit} from "../../model/measurement/MeasurementUnit";
import {OptionType} from "../../model/option-type/OptionType";
import {NomenclatureForm} from "../../model/nomenclature/NomenclatureForm";
import {
    useLazyGetNomenclatureQuery,
    useLazySearchNomenclatureGuidePageQuery,
    useLazySearchMeasurementUnitGuidePageQuery
} from "../../store/backend-api/backend.api";
import {DeleteButtonText} from "../ui/buttons/DeleteButtonText";

interface FormProps {
    control: Control<FormFields>,
    setValue: UseFormSetValue<any>,
    resetField: UseFormResetField<any>,
    deletedProductsIds: number[],
    setDeletedProductsIds: (ids: number[]) => void,
    editFromModalForChoiceMaterials?: boolean,
    editFromModalForChoiceProducts?: boolean,
    stepId?: number
}

interface FormFields {
    selectedProduct: string | null,
    products: {
        productId: number | null,
        myProductId: string,
        isTarget: boolean,
        productName: OptionType<number> | null,
        productQuantity: string,
        productSize: string,
        sizeMeasurementUnit: OptionType<number> | null,
        drawingNumber: string,
        outgoingInStep: string,
        includedInStep: number | null,
        tariff: string,
        defaultProductNomenclatureId: number | null
    }[]
}

export function InternalProductsForm(props: FormProps) {
    const [doSearchNomenclatureGuidePage] = useLazySearchNomenclatureGuidePageQuery()
    const [doSearchMeasurementGuidePage] = useLazySearchMeasurementUnitGuidePageQuery()
    const [doGetNomenclature, {
        data: nomenclatureForEdit,
        isSuccess: isSuccessGetNomenclature
    }] = useLazyGetNomenclatureQuery()
    const [modalForNomenclatureEditIsOpen, setModalForNomenclatureEditIsOpen] = useState<boolean>(false)
    const [modalForNomenclatureCreateIsOpen, setModalForNomenclatureCreateIsOpen] = useState<boolean>(false)
    const [createdNomenclatureOption, setCreatedNomenclatureOption] = useState<OptionType<number> | null>(null)
    const [selectedMaterialFieldIndex, setSelectedMaterialFieldIndex] = useState<number | null>(null)
    const [editedNomenclature, setEditedNomenclature] = useState<null | NomenclatureForm>(null)
    const control: Control<FormFields> = props.control
    const currentFormValue = useWatch({control})

    ReactModal.defaultStyles = {
        overlay: {
            ...ReactModal.defaultStyles.overlay,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            maxHeight: "90%",
            overflowX: 'auto',
            overflowY: 'auto'
        }
    }

    const {fields, append, remove, update} = useFieldArray({
        name: "products",
        control,
        rules: {}
    })

    function onClickTrashIcon(field: FieldArrayWithId<FormFields, "products", "id">, index: number) {
        if (field.productId !== null) {
            props.setDeletedProductsIds([...props.deletedProductsIds, field.productId!])
        }
        remove(index)
    }

    function rowMapper() {
        return (
            <tbody>
            {fields.map((field, index) =>
                <tr key={field.id}>
                    {props.editFromModalForChoiceMaterials && props.stepId &&
                        <td className="border border-black text-center align-bottom">
                            <Controller control={control} name={`products.${index}.includedInStep`} rules={{}}
                                        render={({field: {value, onChange}}) =>
                                            <input className="w-5 h-5" type="checkbox" value={props.stepId}
                                                   onChange={(event) =>
                                                       onChange(event.target.checked ? event.target.value : null)}
                                                   checked={value !== null}></input>
                                        }/>
                        </td>}
                    {props.editFromModalForChoiceProducts && props.stepId &&
                        <td className="border border-black text-center align-bottom">
                            <Controller control={control} name={"selectedProduct"} rules={{}}
                                        render={({field: {value, onChange}}) =>
                                            <input className="w-5 h-5 " type="radio" name={"radioButton"}
                                                   value={field.myProductId} onChange={(event) => {
                                                onChange(event.target.checked ? event.target.value : null)
                                            }}
                                                   checked={field.myProductId === value}></input>
                                        }/>
                        </td>}
                    {(props.editFromModalForChoiceMaterials || props.editFromModalForChoiceProducts) && props.stepId &&
                        <td className="border border-black px-2 text-sm font-light">{field.productId}</td>}
                    <td className="border border-black"><ControlledPaginateAsyncSelectForTable control={control}
                                                                                               rules={{
                                                                                                   required: {
                                                                                                       value: true,
                                                                                                       message: "Это обязательное поле"
                                                                                                   }
                                                                                               }}
                                                                                               name={`products.${index}.productName`}
                                                                                               request={doSearchNomenclatureGuidePage}
                                                                                               mapFunc={(data: TablePage<NomenclatureGuideRow>) =>
                                                                                                   data.rows.map(row =>
                                                                                                       ({
                                                                                                           label: `${row.fullName}, ${row.measurementUnit}`,
                                                                                                           value: row.id
                                                                                                       })
                                                                                                   )}
                                                                                               additionalOptions={[{
                                                                                                   label: "Создать новое изделие",
                                                                                                   value: -1
                                                                                               }]}
                                                                                               isClearable={false}
                                                                                               setModalIsOpen={setModalForNomenclatureCreateIsOpen}
                                                                                               selectedFieldIndex={index}
                                                                                               setSelectedFieldIndex={setSelectedMaterialFieldIndex}/>
                    </td>
                    {currentFormValue.products?.[index]?.productName !== null && currentFormValue.products?.[index]?.productName !== undefined &&
                        <td title={"Редактировать номенклатуру"} className="border border-black cursor-pointer"
                            onClick={() => {
                                doGetNomenclature(currentFormValue.products?.[index]?.productName?.value!)
                            }}>
                            <PiPencilLight className="mt-[2px] h-5 w-5 hover:text-blue-900"/>
                        </td>}
                    {(currentFormValue.products?.[index]?.productName === null || currentFormValue.products?.[index]?.productName === undefined) &&
                        <td className="border border-black"></td>}
                    <td className="border border-black"><ControlledInputFieldForTable control={control} rules={{
                        required: {
                            value: true,
                            message: "Это обязательное поле"
                        },
                        pattern: {
                            value: /^[0-9]*[.]?[0-9]{1,3}$/g,
                            message: 'Введите число с 3 или менее знаками после "."',
                        },
                        validate: {
                            positive: (value: string) => value !== '0' || 'Количество больше нуля'
                        }
                    }}
                                                                                      name={`products.${index}.productQuantity`}
                                                                                      additionalStyles={"w-[100px]"}/>
                    </td>
                    <td className="border border-black"><ControlledInputFieldForTable control={control} rules={{
                        pattern: {
                            value: /^[0-9]*[.]?[0-9]{1,3}$/g,
                            message: 'Введите положительное число с 3 или менее знаками после "."',
                        },
                        validate: {
                            positive: (value: string) => value !== '0' || 'Размер больше нуля'
                        }
                    }}
                                                                                      name={`products.${index}.productSize`}
                                                                                      additionalStyles={"w-[100px]"}/>
                    </td>
                    <td className="border border-black"><ControlledPaginateAsyncSelectForTable control={control}
                                                                                               rules={{}}
                                                                                               name={`products.${index}.sizeMeasurementUnit`}
                                                                                               request={doSearchMeasurementGuidePage}
                                                                                               mapFunc={(data: TablePage<MeasurementUnit>) =>
                                                                                                   data.rows.map(row =>
                                                                                                       ({
                                                                                                           label: `${row.name}`,
                                                                                                           value: row.id
                                                                                                       })
                                                                                                   )}/>
                    </td>
                    <td className="border border-black text-sm font-light px-2">{currentFormValue.products?.[index]?.productName?.value !== field.defaultProductNomenclatureId || field.defaultProductNomenclatureId === null || !field.drawingNumber ? "–" : field.drawingNumber}</td>
                    {(props.editFromModalForChoiceMaterials === undefined || !props.editFromModalForChoiceMaterials) && (props.editFromModalForChoiceProducts === undefined || !props.editFromModalForChoiceProducts) && <>
                        <td className="border border-black w-auto"><ControlledInputFieldForTable control={control}
                                                                                                 rules={{}}
                                                                                                 name={`products.${index}.outgoingInStep`}
                                                                                                 additionalStyles={"w-full"}
                                                                                                 readonly={true}/>
                        </td>
                        <td className="border border-black w-auto"><ControlledInputFieldForTable control={control}
                                                                                                 rules={{}}
                                                                                                 name={`products.${index}.includedInStep`}
                                                                                                 additionalStyles={"w-full"}
                                                                                                 readonly={true}/>
                        </td>
                    </>}
                    <td className="border border-black"><ControlledInputFieldForTable control={control} rules={{
                        pattern: {
                            value: /^[0-9]*[.]?[0-9]{1,2}$/g,
                            message: 'Введите число с 2 или менее знаками после "."',
                        },
                        validate: {
                            positive: (value: string) => value !== '0' || 'Сумма больше нуля'
                        }
                    }}
                                                                                      name={`products.${index}.tariff`}
                                                                                      additionalStyles={"w-[100px]"}
                                                                                      placeholder={"Почасовой"}/>
                    </td>
                    {!field.isTarget &&
                        <td title={"Удалить материал"} className="border border-black cursor-pointer" onClick={() => {
                            onClickTrashIcon(field, index)
                        }}><PiTrashLight className="mt-[2px] h-5 w-5 hover:text-red-900"/>
                        </td>}
                    {field.isTarget && <td className="border border-black"></td>}
                </tr>)}
            </tbody>
        )
    }

    function chooseAllProducts() {
        fields.forEach((field, index) => {
            if (field.isTarget) {
                update(index, field)
            } else {
                update(index, {...field, includedInStep: props.stepId!})
            }
        })
    }

    function resetChoiceProducts() {
        fields.forEach((field, index) => update(index, {...field, includedInStep: null}))
    }

    useEffect(() => {
        if (!modalForNomenclatureCreateIsOpen && createdNomenclatureOption !== null && selectedMaterialFieldIndex !== null) {
            props.setValue(`products.${selectedMaterialFieldIndex}.productName`, createdNomenclatureOption)
            setSelectedMaterialFieldIndex(null)
            setCreatedNomenclatureOption(null)
        }
        if (!modalForNomenclatureCreateIsOpen && createdNomenclatureOption === null && selectedMaterialFieldIndex !== null) {
            props.resetField(`products.${selectedMaterialFieldIndex}.productName`)
            setSelectedMaterialFieldIndex(null)
        }
    }, [modalForNomenclatureCreateIsOpen]);

    useEffect(() => {
        if (isSuccessGetNomenclature) {
            setEditedNomenclature(nomenclatureForEdit!)
            setModalForNomenclatureEditIsOpen(true)
        }
    }, [nomenclatureForEdit]);

    return (<>
            {props.editFromModalForChoiceMaterials && <div className="flex flex-row gap-x-4 mb-2">
                <AddButtonText onClick={chooseAllProducts} name={"Выбрать всё"}/>
                <div className="-mt-[6px]">
                    <DeleteButtonText onClick={resetChoiceProducts} label={"Сбросить выбор"}/>
                </div>
            </div>}
            <table className="border-collapse mb-3">
                <thead>
                <tr>
                    {(props.editFromModalForChoiceMaterials || props.editFromModalForChoiceProducts) && <>
                        <th className="border border-black px-1 text-sm font-medium">Выбор для передела</th>
                        <th className="border border-black px-1 text-sm font-medium">ID</th>
                    </>}
                    <th className="border border-black px-1 text-sm font-medium">Наименование</th>
                    <th className="border border-black px-1 text-sm font-medium"></th>
                    <th className="border border-black px-1 text-sm font-medium">Количество</th>
                    <th className="border border-black px-1 text-sm font-medium">Размер</th>
                    <th className="border border-black px-1 text-sm font-medium">Ед. изм. размера</th>
                    <th className="border border-black px-1 text-sm font-medium">Номер чертежа</th>
                    {(props.editFromModalForChoiceMaterials === undefined || !props.editFromModalForChoiceMaterials) && (props.editFromModalForChoiceProducts === undefined || !props.editFromModalForChoiceProducts) && <>
                        <th className="border border-black px-1 text-sm font-medium">Исходящее в переделе</th>
                        <th className="border border-black px-1 text-sm font-medium">Входящее в переделе</th>
                    </>}
                    <th className="border border-black px-1 text-sm font-medium">Тариф</th>
                    <th className="border border-black px-1 text-sm font-medium"></th>
                </tr>
                </thead>
                {rowMapper()}
            </table>
            <AddButtonText onClick={() => {
                append({
                    productId: null,
                    myProductId: crypto.randomUUID(),
                    isTarget: false,
                    productName: null,
                    productQuantity: "",
                    productSize: "",
                    sizeMeasurementUnit: null,
                    drawingNumber: "",
                    outgoingInStep: "",
                    includedInStep: null,
                    tariff: "",
                    defaultProductNomenclatureId: null
                })
            }} name={"Добавить изделие"}/>
            <ReactModal isOpen={modalForNomenclatureCreateIsOpen}
                        onRequestClose={() => setModalForNomenclatureCreateIsOpen(false)}>
                <div className="flex flex-row justify-end">
                    <button type={"button"}
                            title="Закрыть"
                            onClick={() => setModalForNomenclatureCreateIsOpen(false)}
                            className="-mr-1 text-gray-400/[.60] rounded-[5px]">
                        <FiX className="h-8 w-8"/>
                    </button>
                </div>
                <MaterialOrProductCreateForm setCreatedNomenclatureOption={setCreatedNomenclatureOption}
                                             setModalIsOpen={setModalForNomenclatureCreateIsOpen}/>
            </ReactModal>
            {editedNomenclature !== null && <ReactModal isOpen={modalForNomenclatureEditIsOpen}
                                                        onRequestClose={() => {
                                                            setModalForNomenclatureEditIsOpen(false)
                                                            setEditedNomenclature(null)
                                                        }}>
                <div className="flex flex-row justify-end">
                    <button type={"button"}
                            title="Закрыть"
                            onClick={() => {
                                setModalForNomenclatureEditIsOpen(false)
                                setEditedNomenclature(null)
                            }}
                            className="-mr-1 text-gray-400/[.60] rounded-[5px]">
                        <FiX className="h-8 w-8"/>
                    </button>
                </div>
                <NomenclatureFormEdit data={editedNomenclature} editFromBillOfMaterialInterface={true}
                                      onSaveFromBillOnMaterialsUI={setModalForNomenclatureEditIsOpen}/>
            </ReactModal>}
        </>
    )
}