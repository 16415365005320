import React from "react";
import {FcGenericSortingAsc, FcGenericSortingDesc} from "react-icons/fc";
import {RiExpandUpDownFill} from "react-icons/ri";

interface Props {
    fieldName?: string;
    filter?: any;
    sortingFunction?: () => void;
}

export const SortButton = ({fieldName, filter, sortingFunction}: Props) => {
    const isVisible = !!sortingFunction && !!filter && 'sortedField' in filter;

    return (
        <>
            {isVisible && <div onClick={sortingFunction} className='flex items-center mt-[1px] cursor-pointer'>
                {(filter.sortedField === fieldName) ?
                    (filter.sortOrder === "ASC" ?
                        <FcGenericSortingAsc className="h-4 w-4"/>
                        : <FcGenericSortingDesc className="h-4 w-4"/>)
                    : <RiExpandUpDownFill className="h-4 w-4"/>}
            </div>}
        </>
    );
};