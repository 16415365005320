import React, {useState} from "react";
import {OptionType} from "../../../../model/option-type/OptionType";

interface Props {
    options: OptionType<string | number>[];
    initialValue: string | number;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    radioGroupName?: string;
}

export const RadioGroup = ({ options, initialValue, radioGroupName, onChange }: Props) => {
    const [value, setValue] = useState<string | number | undefined>(initialValue !== undefined ? initialValue : undefined);

    return (
        <div className='flex flex-col gap-2'>
            {options.map((option) => <label className='cursor-pointer flex flex-row gap-1.5'><input
                className='mt-0.5'
                type='radio'
                value={option.value}
                name={radioGroupName || 'RadioGroup'}
                onChange={(event) => {
                    onChange(event);
                    setValue(event.target.value)
                }}
                checked={option.value === value}
            />{option.label}</label>)}
        </div>
    );
};