import React, {useEffect} from "react";
import {useSearchProductionOrderJournalPageQuery} from "../../store/backend-api/backend.api";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {useAppSelector} from "../../hooks/useAppSelector";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {GuideTopBox} from "../../components/ui/table-components/GuideTopBox";
import {useFilterNodesActions} from "../../hooks/useMenuFiltersActions";
import {productionOrderStatusToString} from "../../data/ProductionOrderData";
import {useDataActions} from "../../hooks/useDataActions";
import {DEFAULT_PRODUCTION_ORDER_ARCHIVE_FILTER} from "../../model/filters/DefaultFilters";
import {MultiSelect} from "../../components/ui/form-components/standalone/MultiSelect";
import {OptionType} from "../../model/option-type/OptionType";
import {ProductionOrderStatus} from "../../model/production-order/ProductionOrder";
import {ProductionOrderArchiveTable} from "../../components/production-order-archive/ProductionOrderArchiveTable";
import {tablePageStyle} from "../../utils/TailwindStyles";

export function ProductionOrderArchiveJournal() {
    const {updateFilterAndClearData} = useFiltersActions()
    const {addFiltersToMenu} = useFilterNodesActions()
    const {addData} = useDataActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER].filter;

    const data = useAppSelector(state => state.data.data[TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER
        ])

    const {data: tablePage} = useSearchProductionOrderJournalPageQuery(filter)

    const selectOptions = Array.from(productionOrderStatusToString.keys()).map(key => ({
        label: productionOrderStatusToString.get(key)!,
        value: key
    }))

    useEffect(() => {
        addFiltersToMenu({
            key: TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER,
            value: [
                {
                    filterName: "Статус заказа",
                    filter: <MultiSelect options={selectOptions}
                                         onChange={(options: readonly OptionType<ProductionOrderStatus>[] | null) => {
                                             updateFilterAndClearData({
                                                 key: TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER,
                                                 value: {
                                                     ...filter,
                                                     status: options ? options.map(option => option.value) : []
                                                 }
                                             })
                                         }}
                                         width={"270px"}
                                         defaultOptions={filter.status.map(status => ({
                                             label: productionOrderStatusToString.get(status)!,
                                             value: status
                                         }))}
                    />
                }
            ]
        })
    }, [filter.status]);

    useEffect(() => {
        if (tablePage) {
            if (!data || data.rows.length < (tablePage.pageNumber * DEFAULT_PRODUCTION_ORDER_ARCHIVE_FILTER.pageSize) + 1) {
                addData({key: TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER, data: tablePage})
            }
        }

    }, [tablePage]);

    return (<>
        <div className={tablePageStyle}>
            <GuideTopBox tableFilterKey={TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER}
                         searchInfo={"Поиск по № Work и основной продукции."}/>
            {data && <ProductionOrderArchiveTable data={data}/>}
        </div>
    </>)
}