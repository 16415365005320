import {
    TechnologicalProcessChartTable
} from "../../components/technological-process-chart/TechnologicalProcessChartTable";
import React, {useEffect} from "react";
import {useSearchTechnologicalProcessChartGuidePageQuery} from "../../store/backend-api/backend.api";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {useFilterNodesActions} from "../../hooks/useMenuFiltersActions";
import {GuideTopBox} from "../../components/ui/table-components/GuideTopBox";
import {productionNomenclatureClassifierToString} from "../../data/NomenclatureData";
import {DEFAULT_TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER} from "../../model/filters/DefaultFilters";
import {useDataActions} from "../../hooks/useDataActions";
import {MultiSelect} from "../../components/ui/form-components/standalone/MultiSelect";
import {OptionType} from "../../model/option-type/OptionType";
import {ProductionNomenclatureClassifier} from "../../model/nomenclature/NomenclatureForm";
import {tablePageStyle} from "../../utils/TailwindStyles";

export function TechnologicalProcessChartGuide() {
    const {updateFilterAndClearData} = useFiltersActions()
    const {addFiltersToMenu} = useFilterNodesActions()
    const {addData} = useDataActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER].filter;

    const data = useAppSelector(state => state.data.data[TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER])

    const {data: tablePage} = useSearchTechnologicalProcessChartGuidePageQuery(filter)

    const selectOptions = Array.from(productionNomenclatureClassifierToString.keys()).map(key => ({
        label: productionNomenclatureClassifierToString.get(key)!,
        value: key
    }))

    useEffect(() => {
        if (tablePage) {
            if (!data || data.rows.length < (tablePage.pageNumber * DEFAULT_TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER.pageSize) + 1) {
                addData({key: TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER, data: tablePage})
            }
        }

    }, [tablePage]);

    useEffect(() => {
        addFiltersToMenu({
            key: TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER,
            value: [{
                filterName: "Производственный классификатор",
                filter: <MultiSelect
                    options={selectOptions}
                    onChange={(options: readonly OptionType<ProductionNomenclatureClassifier>[] | null) => {
                        updateFilterAndClearData({
                            key: TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER,
                            value: {
                                ...filter,
                                productionNomenclatureClassifier: options ? options.map(option => option.value) : []
                            }
                        })
                    }}
                    width={"270px"}
                    defaultOptions={filter.productionNomenclatureClassifier.map(value => ({
                        label: productionNomenclatureClassifierToString.get(value)!,
                        value: value
                    }))}/>
            }]
        })
    }, [filter.productionNomenclatureClassifier])

    return (
        <div className={tablePageStyle}>
            <GuideTopBox tableFilterKey={TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER}
                         createNavigationURL={"/bill-of-materials/new"}
                         searchInfo={"Поиск по наименованию и основному артикулу целевого изделия."}/>
            {data && <TechnologicalProcessChartTable data={data}/>}
        </div>
    )
}