import React from "react";

interface ButtonProps {
    disabled?: boolean,
    buttonName: string,
    onClickButton: () => void,
    additionalStyles?: string
}

export function Button (props: ButtonProps) {
    return (
        <button type={"button"} disabled={props.disabled}
                className={`${props.additionalStyles || "ml-3"} whitespace-nowrap h-6 border-[1.5px] border-black/[.30] rounded px-1.5 pb-1 text-sm font-normal text-black/[.67] transition disabled:opacity-50 disabled:hover:no-underline hover:underline hover:underline-offset-[3px] hover:decoration-[0.5px]`}
                onClick={props.onClickButton}>
            {props.buttonName}
        </button>
    )
}