import {css} from "@emotion/css";

export const styles = {
    default: () => css`
      &:hover {
        background-color: rgba(59, 130, 246, 0.1);
      }
    `,
    shadow: () => css`
      box-shadow: inset 0 0 0 1.5px #3b82f6;
    `,
};