import React from "react";
import {useHasAuthority} from "../../hooks/TokensHooks";
import {SystemRole} from "../../model/administrative-roles/AdministrativeRole";


interface ComponentProps {
    children: React.ReactNode,
    authorities?: string[]
}

export function NavLinkGroup({ children, authorities }: ComponentProps) {
    const possibleAuthorities: string | string[] = authorities && authorities.length !== 0 ? authorities : SystemRole.ROLE_ADMIN
    const hasAuthority = useHasAuthority(possibleAuthorities)

    return (
        <div className={hasAuthority ? "border-b-[1px] border-slate-400/[.80] grow pb-[1px] mb-[1px]" : "hidden"}>
            {children}
        </div>
    );
}