import React, {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Material} from "../../model/technological-process-chart/TechnologicalProcessChart";
import {
    useCreateMaterialOfBillOfMaterialsMutation,
    useDeleteMaterialOfBillOfMaterialsMutation,
    useUpdateMaterialOfBillOfMaterialsMutation
} from "../../store/backend-api/backend.api";
import {OptionType} from "../../model/option-type/OptionType";
import {SaveButton} from "../ui/buttons/SaveButton";
import isEqual from "lodash.isequal";
import ReactModal from "react-modal";
import {InternalMaterialsForm} from "./InternalMaterialsForm";

interface FormProps {
    materials: Material[],
    billOfMaterialsId: number,
    editFromModal?: boolean,
    stepId?: number
}

interface FormFields {
    materials: {
        materialId: number | null,
        materialName: OptionType<number> | null,
        materialQuantity: string,
        materialStep: number | null,
        targetBillOfMaterialsId: OptionType<number> | null
    }[]
}

export function MaterialsFormTemplate(props: FormProps) {
    const [doUpdateMaterialOfBillOfMaterials] = useUpdateMaterialOfBillOfMaterialsMutation()
    const [doCreateMaterialOfBillOfMaterials] = useCreateMaterialOfBillOfMaterialsMutation()
    const [doDeleteMaterialOfBillOfMaterials] = useDeleteMaterialOfBillOfMaterialsMutation()
    const [deletedMaterialIds, setDeletedMaterialIds] = useState<number[]>([])
    const defaultValues = (props.editFromModal !== undefined && props.editFromModal && props.stepId !== undefined ? props.materials.filter(material => material.isMaterialIn === props.stepId || material.isMaterialIn === null) : props.materials).map(material => ({
        materialId: material.id,
        materialName: ({
            label: `${material.nomenclature.fullName}, ${material.nomenclature.measurementUnit.name}`!,
            value: material.nomenclature.id
        }!)!,
        materialQuantity: material.quantity !== null ? material.quantity : "",
        materialStep: material.isMaterialIn,
        targetBillOfMaterialsId: material.targetBillOfMaterialsId !== null ? {label: `${material.nomenclature.name}`, value: material.targetBillOfMaterialsId} : null
    }))
    const {control, handleSubmit, formState: {isDirty}, setValue, resetField} = useForm<FormFields>({
        mode: "onChange",
        defaultValues: {materials: defaultValues}
    })

    ReactModal.defaultStyles = {
        overlay: {
            ...ReactModal.defaultStyles.overlay,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            maxHeight: "90%",
            overflowX: 'auto',
            overflowY: 'auto'
        }
    }
    const onClickSaveButton: SubmitHandler<FormFields> = data => {
        if (!isEqual(data, defaultValues)) {
            data.materials.forEach(material => {
                if (material.materialId !== null && !isEqual(material, defaultValues.find(value => value.materialId === material.materialId))) {
                    doUpdateMaterialOfBillOfMaterials({
                        billOfMaterialsId: props.billOfMaterialsId,
                        materialId: material.materialId,
                        body: {
                            quantity: material.materialQuantity !== "" ? material.materialQuantity : null,
                            nomenclatureId: material.materialName!.value,
                            isMaterialIn: material.materialStep,
                            targetBillOfMaterialsId: material.targetBillOfMaterialsId !== null ? material.targetBillOfMaterialsId.value : null
                        }
                    })
                }
                if (material.materialId === null) {
                    doCreateMaterialOfBillOfMaterials({
                        billOfMaterialsId: props.billOfMaterialsId,
                        body: {
                            quantity: material.materialQuantity !== "" ? material.materialQuantity : null,
                            nomenclatureId: material.materialName!.value,
                            isMaterialIn: material.materialStep,
                            targetBillOfMaterialsId: material.targetBillOfMaterialsId !== null ? material.targetBillOfMaterialsId.value : null
                        }
                    })
                }
            })
            if (deletedMaterialIds.length !== 0) {
                deletedMaterialIds.forEach(id => doDeleteMaterialOfBillOfMaterials({
                    billOfMaterialsId: props.billOfMaterialsId,
                    materialId: id
                }))
            }
        }
    }

    return (
        <form onSubmit={handleSubmit(onClickSaveButton)} className="px-2 pt-[18px] max-w-full">
            <InternalMaterialsForm control={control} setValue={setValue} resetField={resetField} deletedMaterialsIds={deletedMaterialIds} setDeletedMaterialsIds={setDeletedMaterialIds}/>
            {isDirty && <>
                <div className="border-b-[1px] border-slate-400/[.80] border-dotted h-1 w-full mt-3 mb-6"></div>
                <div className="flex flex-row justify-end">
                    <div id={"button1"}>
                        <SaveButton onClick={handleSubmit(onClickSaveButton)}/>
                    </div>
                </div>
            </>}
        </form>
    )
}