import React, {useState} from "react";
import {Tab, Tabs} from "../tabs/Tabs";
import {BasicInformationFormTemplate} from "./BasicInformationFormTemplate";
import {BackButton} from "../ui/buttons/BackButton";
import {formHeaderStyle, formStyle} from "../../utils/TailwindStyles";

export function TechnologicalProcessChartFormCreate() {
    const [selectedTabId, setSelectedTabId] = useState<number>(0)

    function onClickTab(id: number) {
        setSelectedTabId(id)
    }

    const tabs: Tab[] = [{
        id: 0,
        label: "Основная информация"
    },
        {
            id: 1,
            label: "Материалы",
            readonly: true
        },
        {
            id: 2,
            label: "Целевые и промежуточные изделия",
            readonly: true
        },
        {
            id: 3,
            label: "Этапы",
            readonly: true
        }]

    return (<div className={formStyle}>
        <div className={formHeaderStyle}>
            <Tabs tabs={tabs} selectedTabId={selectedTabId} onClick={onClickTab}/>
            <BackButton/>
        </div>
        {selectedTabId === 0 && <div>
            <BasicInformationFormTemplate/>
        </div>}
    </div>)
}