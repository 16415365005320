import React from "react";
import {
    TechnologicalProcessChartFormCreate
} from "../../components/technological-process-chart/TechnologicalProcessChartFormCreate";

export function CreateTechnologicalProcessChartForm() {

    return (
        <TechnologicalProcessChartFormCreate/>
    )
}