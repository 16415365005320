import React, {useEffect} from 'react';
import {IndividualsTable} from "../../components/individuals/IndividualsTable";
import {Gender} from "../../model/individuals/Individuals";
import {useFilterNodesActions} from "../../hooks/useMenuFiltersActions";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {useAppSelector} from "../../hooks/useAppSelector";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {useSearchIndividualsGuidePageQuery} from "../../store/backend-api/backend.api";
import {GuideTopBox} from "../../components/ui/table-components/GuideTopBox";
import {NewSelect} from "../../components/ui/form-components/standalone/NewSelect";
import {booleansToString, gendersToString} from "../../data/IndividualsData";
import {OptionType} from "../../model/option-type/OptionType";
import {UpdateFilter} from "../../store/slices/filters.slice";
import {DEFAULT_INDIVIDUALS_GUIDE_FILTER} from "../../model/filters/DefaultFilters";
import {useDataActions} from "../../hooks/useDataActions";
import {tablePageStyle} from "../../utils/TailwindStyles";

export function IndividualsGuide() {
    const {addFiltersToMenu} = useFilterNodesActions()
    const {updateFilterAndClearData} = useFiltersActions()
    const {addData} = useDataActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.INDIVIDUALS_GUIDE_FILTER].filter;

    const data = useAppSelector(state => state.data.data[TableFilterKeys.INDIVIDUALS_GUIDE_FILTER])

    const {data: tablePage} = useSearchIndividualsGuidePageQuery(filter)

    useEffect(() => {
            addFiltersToMenu({
                key: TableFilterKeys.INDIVIDUALS_GUIDE_FILTER,
                value: [
                    {
                        filterName: "Пол",
                        filter: <NewSelect
                            options={Object.keys(Gender).map(key => ({label: gendersToString.get(Gender[key as keyof typeof Gender])!, value: Gender[key as keyof typeof Gender]}))}
                            onChange={(option: OptionType<Gender> | null) => {
                                const newFilter: UpdateFilter = {
                                    key: TableFilterKeys.INDIVIDUALS_GUIDE_FILTER,
                                    value: {...filter, gender: option ? option.value : null}
                                }
                                updateFilterAndClearData(newFilter)
                            }}
                            defaultOption={filter.gender ? {label: gendersToString.get(filter.gender)!, value: filter.gender} : undefined}
                            width={"259px"}/>
                    },
                    {
                        filterName: "Является пользователем",
                        filter: <NewSelect
                            options={[{label: "Да", value: true}, {label: "Нет", value: false}]}
                            onChange={(option: OptionType<boolean> | null) => {
                                const newFilter: UpdateFilter = {
                                    key: TableFilterKeys.INDIVIDUALS_GUIDE_FILTER,
                                    value: {...filter, isUser: option ? option.value : null}
                                }
                                updateFilterAndClearData(newFilter)
                            }}
                            defaultOption={filter.isUser !== null ? {label: booleansToString.get(filter.isUser)!, value: filter.isUser} : undefined}
                            width={"259px"}/>
                    }
                ]
            })
        },
        [filter.gender, filter.isUser]
    );

    useEffect(() => {
        if (tablePage) {
            if (!data || data.rows.length < (tablePage.pageNumber * DEFAULT_INDIVIDUALS_GUIDE_FILTER.pageSize) + 1) {
                addData({key: TableFilterKeys.INDIVIDUALS_GUIDE_FILTER, data: tablePage})
            }
        }

    }, [tablePage]);

    return (
        <div className={tablePageStyle}>
            <GuideTopBox tableFilterKey={TableFilterKeys.INDIVIDUALS_GUIDE_FILTER}
                         createNavigationURL={'/individuals/new'}
                         searchInfo={"Поиск по ФИО."}/>
            {data && <IndividualsTable data={data}/>}
        </div>
    )
}
