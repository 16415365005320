import React from "react";

interface InputProps {
    additionalStyles?: string,
    onChange: (value: string | null) => void,
    placeholder?: string,
    value: string
}

export function SearchInput(props: InputProps) {
    return (
        <input type="text"
                   className={`${props.additionalStyles} min-w-16 w-full grow h-full overflow-hidden border-[1.5px] border-black/[.30] px-3 pb-0.5 my-auto focus:border-blue-500 focus:outline-none text-left text-sm font-light text-gray-500 rounded`}
                   placeholder={props.placeholder} defaultValue={props.value}
                   onChange={(event) => props.onChange(event.target.value)}/>

    )
}