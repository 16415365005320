import React from "react";
import {Table, TableColumn} from "../../tables/Table";
import {productionOrderStatusToString} from "../../../data/ProductionOrderData";
import {ProgressButton} from "../../ui/buttons/ProgressButton";
import {useNavigate} from "react-router-dom";
import {TablePage} from "../../../model/utils/TablePage";
import {ProductionOrderJournalRow, ProductionOrderSortedField} from "../../../model/production-order/ProductionOrder";
import {useFiltersActions} from "../../../hooks/useFiltersActions";
import {TableFilterKeys} from "../../../model/filters/TableFiltersDefinitions";
import {ProductionOrderPopupContent} from "./components/ProductionOrderPopupContent/ProductionOrderPopupContent";
import {PiListNumbers} from "react-icons/pi";
import {SortedOrder} from "../../../model/utils/SortedOrder";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {useSearchProductionStagesQueueQuery} from "../../../store/backend-api/backend.api";
import {Simulate} from "react-dom/test-utils";


interface TableProps {
    data: TablePage<ProductionOrderJournalRow>
}

export function ProductionOrderJournalTable(props: TableProps) {
    const {updateFilterPage, updateFilterAndClearData} = useFiltersActions();
    const {data: productionStagesQueue} = useSearchProductionStagesQueueQuery();

    const filtersState = useAppSelector(state => state.sessionStorage.filters)
    const filter = filtersState[TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER].filter;

    const setSortingBy = (currentSortedField: ProductionOrderSortedField) => () => {
        if (filter.sortedField !== currentSortedField) {
            updateFilterAndClearData({
                key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER,
                value: {...filter, sortOrder: SortedOrder.ASC, sortedField: currentSortedField, pageNumber: 0}
            })
        } else {
            if (filter.sortOrder === "ASC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER,
                    value: {...filter, sortOrder: SortedOrder.DSC, pageNumber: 0}
                })
            } else if (filter.sortOrder === "DSC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER,
                    value: {...filter, sortOrder: null, sortedField: null, pageNumber: 0}
                })
            }
        }
    }

    const navigate = useNavigate()

    const priorityColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: <PiListNumbers style={{height: '16px', marginTop: '1px'}}/>,
        title: 'Номер в очереди',
        extractCellContent: row => row.priority,
        sortingFunction: setSortingBy(ProductionOrderSortedField.PRIORITY),
        columnFieldName: ProductionOrderSortedField.PRIORITY
    }

    const workOrderNumberColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: '№ Work',
        extractCellContent: row => row.workOrderNumber,
        popupContent: <ProductionOrderPopupContent tableFilterKey={TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER}
                                                   filterFieldName={'externalIdSearch'}/>,
        sortingFunction: setSortingBy(ProductionOrderSortedField.EXTERNAL_ID),
        columnFieldName: ProductionOrderSortedField.EXTERNAL_ID
    }

    const productionLineColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Пр. линия',
        title: 'Производственная линия',
        extractCellContent: row => row.productionLine?.name || "–",
        sortingFunction: setSortingBy(ProductionOrderSortedField.PRODUCTION_LINE),
        columnFieldName: ProductionOrderSortedField.PRODUCTION_LINE
    }

    const mainProductColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Основная продукция',
        extractCellContent: row => row.mainProduct ? `${row.mainProduct?.nomenclature?.name}, ${row.mainProduct?.nomenclature?.measurementUnit?.name || "–"}` : "–",
        popupContent: <ProductionOrderPopupContent tableFilterKey={TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER}
                                                   filterFieldName={'targetItemSearch'}/>,
        sortingFunction: setSortingBy(ProductionOrderSortedField.TARGET_ITEM),
        columnFieldName: ProductionOrderSortedField.TARGET_ITEM
    }

    const additionalEquipmentColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Доп. комплектация',
        title: 'Дополнительная комплектация',
        displayAtList: true,
        additionalStyles: 'max-w-[300px] w-max',
        extractCellContent: row => row.additionalEquipment.length === 0 ? "–" : row.additionalEquipment.map(equipment => equipment.nomenclature.name),
        popupContent: <ProductionOrderPopupContent tableFilterKey={TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER}
                                                   filterFieldName={'additionalItemsSearch'}/>
    }

    const productionOrderStatusColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Статус заказа',
        extractCellContent: row => productionOrderStatusToString.get(row.status),
        sortingFunction: setSortingBy(ProductionOrderSortedField.STATUS),
        columnFieldName: ProductionOrderSortedField.STATUS
    }

    const productionTaskProgressColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Прогресс выполнения заказа',
        showOnOneTableRow: true,
        extractCellContent: row =>
            productionStagesQueue?.queue?.map(stage => {
                const progress = row.progress.find(progress => progress.productionStage.id === stage.id);
                return (<ProgressButton data={progress}
                                        productionStage={{id: stage.id, name: stage.name}}
                                        key={stage.order}/>)
            }) || '-'
    }

    const planningProductionEndTimeColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Требуется завершить',
        extractCellContent: row => {
            const date = new Date(row.planningCompletionDate)
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}.${month}.${year}`
        },
        sortingFunction: setSortingBy(ProductionOrderSortedField.PLANNING_COMPLETION_DATE),
        columnFieldName: ProductionOrderSortedField.PLANNING_COMPLETION_DATE
    }

    const productionEndTimeColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Завершён',
        extractCellContent: row => row.completionTime,
        sortingFunction: setSortingBy(ProductionOrderSortedField.COMPLETION_TIME),
        columnFieldName: ProductionOrderSortedField.COMPLETION_TIME
    }

    function actionOnRowClick(row: ProductionOrderJournalRow) {
        navigate(`/production-order?number=${row.documentNumber}`)
    }

    return (
        <Table<ProductionOrderJournalRow>
            tableColumns={[priorityColumn, workOrderNumberColumn, productionLineColumn, mainProductColumn, additionalEquipmentColumn, productionOrderStatusColumn, productionTaskProgressColumn, planningProductionEndTimeColumn, productionEndTimeColumn]}
            rows={props.data.rows}
            onRowClick={actionOnRowClick}
            tableFilterKey={TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => {
                    updateFilterPage({key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER, value: newPageNumber})
                }
            }}
        />
    )
}