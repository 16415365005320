import React from "react";
import {HiOutlineBars3} from "react-icons/hi2";
import {useNavBarActions} from "../../hooks/useNavBarActions";
import {useAppSelector} from "../../hooks/useAppSelector";

export function CollapseButton () {
    const {setIsNavBarVisible} = useNavBarActions()
    const isNavBarVisible = useAppSelector(state => state.navBar.isNavBarVisible)

    return (<button className="flex items-center justify-center h-7 w-7 bg-white border-[0.5px] border-solid border-slate-400/[.80] border-l-0 rounded-r-sm"
                    onClick={() => {setIsNavBarVisible(!isNavBarVisible)}}>
        <HiOutlineBars3 className="stroke-[0.6px] h-[22px] w-[22px]" />
    </button>)
}
