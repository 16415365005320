import React, {useEffect, useState} from "react";
import {AddButtonText} from "../ui/buttons/AddButtonText";
import {FiX} from "react-icons/fi";
import ReactModal from "react-modal";
import {SubmitHandler, useForm} from "react-hook-form";
import {ControlledPaginateAsyncSelect} from "../ui/form-components/controlled/ControlledPaginateAsyncSelect";
import {
    useCreateStageOfBillOfMaterialsMutation,
    useCreateStepOfBillOfMaterialsMutation,
    useDeleteStageOfBillOfMaterialsMutation,
    useDeleteStepOfBillOfMaterialsMutation,
    useLazySearchProductionStagesGuidePageQuery
} from "../../store/backend-api/backend.api";
import {TablePage} from "../../model/utils/TablePage";
import {OptionType} from "../../model/option-type/OptionType";
import {Button} from "../ui/buttons/Button";
import {Material, Product, Stage, Step} from "../../model/technological-process-chart/TechnologicalProcessChart";
import {
    AdministrativeAndFunctionalZonePurpose
} from "../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZone";
import {
    AdministrativeAndFunctionalZoneGuideFilter
} from "../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuideFilter";
import {PiFileTextThin, PiPencilLight, PiTrashLight} from "react-icons/pi";
import {ProductsFormTemplate} from "./ProductsFormTemplate";
import {SelectMaterialsForStepForm} from "./SelectMaterialsForStepForm";
import {ProductionStage} from "../../model/production-stage/ProductionStage";
import {ModalForEditStage} from "./ModalForEditStage";
import {
    ModalForWorkingWithNomenclatureAttachments
} from "../nomenclature/ModalForWorkingWithNomenclatureAttachments";

interface FormProps {
    stages: Stage[],
    steps: Step[],
    materials: Material[],
    products: Product[],
    billOfMaterialsId: number
}

interface FormFields {
    productionStage: OptionType<number> | null
}

export function StagesAndStepsFormTemplate(props: FormProps) {
    const [doSearchProductionStagesGuidePage] = useLazySearchProductionStagesGuidePageQuery()
    const [doCreateStageOfBillOfMaterials, {
        data: stageId,
        isSuccess: isSuccessCreateStage
    }] = useCreateStageOfBillOfMaterialsMutation()
    const [doDeleteStageOfBillOfMaterials] = useDeleteStageOfBillOfMaterialsMutation()
    const [doCreateStepOfBillOfMaterials] = useCreateStepOfBillOfMaterialsMutation()
    const [modalForWorkingWithNomenclatureAttachmentsIsOpen, setModalForWorkingWithNomenclatureAttachmentsIsOpen] = useState<boolean>(false)
    const [selectedNomenclatureId, setSelectedNomenclatureId] = useState<number | null>(null)
    const [doDeleteStepOfBillOfMaterials] = useDeleteStepOfBillOfMaterialsMutation()
    const [modalForCreateStageIsOpen, setModalForCreateStageIsOpen] = useState<boolean>(false)
    const [modalForEditStageIsOpen, setModalForEditStageIsOpen] = useState<boolean>(false)
    const [modalForChoiceMaterialsIsOpen, setModalForChoiceMaterialsIsOpen] = useState<boolean>(false)
    const [modalForChoiceProductsIsOpen, setModalForChoiceProductsIsOpen] = useState<boolean>(false)
    const [editableStepId, setEditableStepId] = useState<number | null>(null)
    const [editableStageId, setEditableStageId] = useState<number | null>(null)
    const {control, handleSubmit} = useForm<FormFields>({
        mode: "onChange",
        defaultValues: {productionStage: null}
    })

    const onClickCreateStageButton: SubmitHandler<FormFields> = data => {
        doCreateStageOfBillOfMaterials({
            billOfMaterialsId: props.billOfMaterialsId, body: {
                productionStageId: data.productionStage!.value,
                order: props.stages.length !== 0 ? props.stages[props.stages.length - 1].order + 1 : 1
            }
        })
    }

    ReactModal.defaultStyles = {
        overlay: {
            ...ReactModal.defaultStyles.overlay,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            maxHeight: "90%",
            overflowX: 'auto',
            overflowY: 'auto'
        }
    }

    function onClickTrashForStepIcon(stepId: number) {
        doDeleteStepOfBillOfMaterials({billOfMaterialsId: props.billOfMaterialsId, stepId: stepId})
    }

    function onClickTrashForStageIcon(stageId: number) {
        doDeleteStageOfBillOfMaterials({billOfMaterialsId: props.billOfMaterialsId, stageId: stageId})
    }

    function onClickEditStageButton(stageId: number) {
        setEditableStageId(stageId)
        setModalForEditStageIsOpen(true)
    }

    function onClickColumnsAboutMaterials(step: Step) {
        setModalForChoiceMaterialsIsOpen(true)
        setEditableStepId(step.id)
    }

    function onClickColumnsAboutProducts(step: Step) {
        setModalForChoiceProductsIsOpen(true)
        setEditableStepId(step.id)
    }

    function onClickFileIcon(nomenclatureId: number) {
        setSelectedNomenclatureId(nomenclatureId)
        setModalForWorkingWithNomenclatureAttachmentsIsOpen(true)
    }

    useEffect(() => {
        if (isSuccessCreateStage) {
            setModalForCreateStageIsOpen(false)
        }
    }, [stageId]);

    function invalidProductInStepColor(product: Product | null): string {
        if (product?.isMaterialIn === product?.isProductIn) {
            return "bg-red-500/[.06]"
        }

        if (!product?.isMaterialIn && !product?.isTarget) {
            return "bg-yellow-500/[.06]"
        }

        return ""
    }

    function invalidMaterialInStepColor(material: Material | null) {
        if (!material?.isMaterial && material?.isMaterialIn === material?.isProductIn) {
            return "bg-red-500/[.06]"
        }

        if (!material?.isMaterial && !material?.isProductIn) {
            return "bg-yellow-500/[.06]";
        }

        return ""
    }

    return (
        <div className="px-2 pt-1 pb-2 max-w-full">
            <AddButtonText onClick={() => setModalForCreateStageIsOpen(true)} name={"Добавить этап"}/>
            <div className="flex flex-col gap-y-6 mt-2">
                {props.stages.map((stage, index) => <div key={stage.id}>
                    <div className="flex flex-row gap-x-5 mb-1">
                        <p>{`${index + 1}. ${stage.productionStageGuideRow.name.toUpperCase()}`}</p>
                        <PiPencilLight title={"Редактировать этап"} onClick={() => onClickEditStageButton(stage.id)}
                                       className="mt-[2px] h-[19px] w-5 hover:text-blue-900 cursor-pointer"/>
                        <PiTrashLight title={"Удалить этап"} onClick={() => onClickTrashForStageIcon(stage.id)}
                                      className="mt-[2px] h-5 w-5 hover:text-red-900 cursor-pointer"/>
                    </div>
                    <table className="w-fit mb-2">
                        <thead>
                        <tr>
                            <th className="border border-black px-1 text-sm font-medium">№</th>
                            <th className="border border-black px-1 text-sm font-medium">Компоненты</th>
                            <th className="border border-black px-1 text-sm font-medium">Количество</th>
                            <th className="border border-black px-1 text-sm font-medium">Изделие</th>
                            <th className="border border-black px-1 text-sm font-medium">Количество</th>
                            <th className="border border-black px-1 text-sm font-medium">Размер изделия</th>
                            <th className="border border-black px-1 text-sm font-medium">Номер чертежа</th>
                            <th className="border border-black px-1 text-sm font-medium">Файлы</th>
                            <th className="border border-black px-1 text-sm font-medium">Тариф</th>
                            <th className="border border-black px-1 text-sm font-medium"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.steps.filter(step => step.billOfMaterialsStageId === stage.id).map((step, index) =>
                            <tr className="text-sm font-light">
                                <td className="border border-black whitespace-nowrap px-2">{index + 1}</td>
                                <td onClick={() => onClickColumnsAboutMaterials(step)}
                                    className={`border border-black whitespace-nowrap px-2 cursor-pointer ${step.materials.length === 0 ? "bg-red-500/[.06]" : ""}`}>{step.materials.length !== 0 ? step.materials.map((material, index) =>
                                    <div
                                        className={`${(step.materials.length !== 1 && index + 1 !== step.materials.length) ? "border-b-[0.5px] border-b-black/[.20]" : ""} ${invalidMaterialInStepColor(material)}`}>{`${material.nomenclature.fullName} ${!material.isMaterial ? `(${material.id})` : ""}`}<br/>
                                    </div>) : "Не выбрано"}</td>
                                <td className="border border-black whitespace-nowrap px-2">{step.materials.length !== 0 ? step.materials.map((material, index) =>
                                    <div
                                        className={(step.materials.length !== 1 && index + 1 !== step.materials.length) ? "border-b-[0.5px] border-b-black/[.20]" : ""}>{material.quantity !== null ? `${material.quantity} ${material.nomenclature.measurementUnit.name}` : "–"}<br/>
                                    </div>) : "–"}</td>
                                <td onClick={() => onClickColumnsAboutProducts(step)}
                                    className={`border border-black whitespace-nowrap px-2 cursor-pointer ${invalidProductInStepColor(step.product)}`}>{step.product !== null ? `${step.product.nomenclature.fullName} (${step.product.id})` : "Не выбрано"}</td>
                                <td className="border border-black whitespace-nowrap px-2">{step.product !== null ? `${step.product.quantity} ${step.product.nomenclature.measurementUnit.name}` : "–"}</td>
                                <td className="border border-black px-2">{step.product !== null && step.product.size.value !== null ? step.product.size.measurementUnit !== undefined && step.product.size.measurementUnit !== null ? `${step.product.size.value} ${step.product.size.measurementUnit?.name}` : step.product.size.value : "–"}</td>
                                <td className="border border-black px-2 w-auto">{step.product !== null && step.product.nomenclature.drawingNumber !== null ? step.product.nomenclature.drawingNumber : "–"}</td>
                                <td className="border border-black text-sm font-light px-2">{step.product ?
                                    <PiFileTextThin
                                        className={step.product.hasAttachments ? "ml-[14px] w-5 h-5 text-green-600 cursor-pointer" : "ml-[14px] w-5 h-5 cursor-pointer"}
                                        onClick={() => onClickFileIcon(step.product!.nomenclature.id)}/> : "–"}</td>
                                <td className="border border-black px-2">{step.product !== null && step.product.tariff !== null ? step.product.tariff : "–"}</td>
                                <td title={"Удалить передел"} className="border border-black"
                                    onClick={() => {
                                        onClickTrashForStepIcon(step.id)
                                    }}><PiTrashLight className="mt-[2px] h-5 w-5 hover:text-red-900"/></td>
                            </tr>)}
                        </tbody>
                    </table>
                    <AddButtonText onClick={() => doCreateStepOfBillOfMaterials({
                        billOfMaterialsId: props.billOfMaterialsId,
                        body: {billOfMaterialsStageId: stage.id}
                    })} name={"Добавить передел"}/>
                </div>)}
            </div>
            {editableStageId && <ModalForEditStage modalForEditStageIsOpen={modalForEditStageIsOpen}
                                                   setModalForEditStageIsOpen={setModalForEditStageIsOpen}
                                                   stage={props.stages.find(stage => stage.id === editableStageId)!}
                                                   setEditableStageId={setEditableStageId}/>}
            <ReactModal isOpen={modalForCreateStageIsOpen}
                        onRequestClose={() => setModalForCreateStageIsOpen(false)}>
                <div className="flex flex-row justify-end">
                    <button type={"button"}
                            title="Закрыть"
                            onClick={() => setModalForCreateStageIsOpen(false)}
                            className="-mr-1 text-gray-400/[.60] rounded-[5px]">
                        <FiX className="h-8 w-8"/>
                    </button>
                </div>
                <form onSubmit={handleSubmit(onClickCreateStageButton)}>
                    <ControlledPaginateAsyncSelect control={control} rules={{
                        required: {
                            value: true,
                            message: "Это обязательное поле"
                        }
                    }} name={"productionStage"} label={"Этап производства"}
                                                   request={doSearchProductionStagesGuidePage}
                                                   mapFunc={(data: TablePage<ProductionStage>) =>
                                                       data.rows.map(row => ({label: `${row.name}`, value: row.id}))
                                                   } filter={{
                        pageNumber: 0,
                        pageSize: 10,
                        search: null,
                        sortOrder: null,
                        purpose: AdministrativeAndFunctionalZonePurpose.PRODUCTION_AREA,
                        sortedField: null
                    } as AdministrativeAndFunctionalZoneGuideFilter}/>
                    <div className="-ml-6 mt-5 flex justify-center">
                        <Button buttonName={"Создать этап"} onClickButton={handleSubmit(onClickCreateStageButton)}/>
                    </div>
                </form>
            </ReactModal>
            <ReactModal isOpen={modalForChoiceMaterialsIsOpen}
                        onRequestClose={() => {
                            setModalForChoiceMaterialsIsOpen(false)
                            setEditableStepId(null)
                        }}>
                <div className="flex flex-row justify-end">
                    <button type={"button"}
                            title="Закрыть"
                            onClick={() => {
                                setModalForChoiceMaterialsIsOpen(false)
                                setEditableStepId(null)
                            }}
                            className="-mr-1 text-gray-400/[.60] rounded-[5px]">
                        <FiX className="h-8 w-8"/>
                    </button>
                </div>
                {editableStepId !== null &&
                    <SelectMaterialsForStepForm stepId={editableStepId} products={props.products}
                                                materials={props.materials}
                                                billOfMaterialsId={props.billOfMaterialsId}/>}
            </ReactModal>
            <ReactModal isOpen={modalForChoiceProductsIsOpen}
                        onRequestClose={() => {
                            setModalForChoiceProductsIsOpen(false)
                            setEditableStepId(null)
                        }}>
                <div className="flex flex-row justify-end">
                    <button type={"button"}
                            title="Закрыть"
                            onClick={() => {
                                setModalForChoiceProductsIsOpen(false)
                                setEditableStepId(null)
                            }}
                            className="-mr-1 text-gray-400/[.60] rounded-[5px]">
                        <FiX className="h-8 w-8"/>
                    </button>
                </div>
                {editableStepId !== null &&
                    <ProductsFormTemplate products={props.products} billOfMaterialsId={props.billOfMaterialsId}
                                          editFromModalForChoiceProducts={true} stepId={editableStepId}/>}
            </ReactModal>
            {selectedNomenclatureId &&
                <ModalForWorkingWithNomenclatureAttachments
                    isOpen={modalForWorkingWithNomenclatureAttachmentsIsOpen}
                    setIsOpen={setModalForWorkingWithNomenclatureAttachmentsIsOpen}
                    nomenclatureId={selectedNomenclatureId}/>}
        </div>
    )
}