import React, {useEffect, useState} from "react";
import {defBaseUrl, useLazyGetUserCardQuery} from "../../store/backend-api/backend.api";
import {Link} from "react-router-dom";
import {PiImageSquareThin, PiQuestionMarkThin, PiUserSquareThin} from "react-icons/pi";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useUserPhotoActions} from "../../hooks/useUserPhotoActions";

export function UserCardComponent() {
    const userPhoto = useAppSelector(state => state.userPhoto.userPhoto)
    const userPhotoId = useAppSelector(state => state.userPhoto.userPhotoId)
    const {setUserPhoto} = useUserPhotoActions()
    const [maxOfWidthOrHeight, setMaxOfWidthOrHeight] = useState<string | null>(null)

    const [doGetUserCard, {data: userCard}] = useLazyGetUserCardQuery()
    const displayName = userCard ? userCard.subject : "Не авторизован"
    const displayRole = userCard ? userCard.role : "Роль отсутствует"
    const tokens = useAppSelector(state => state.localStorage.tokens)

    useEffect(() => {
        if (tokens) {
            doGetUserCard()
        }
    }, [tokens]);

    useEffect(() => {
        if (userPhoto) {
            const url = URL.createObjectURL(userPhoto)
            const img = new Image()
            img.src = url
            img.onload = () => {
                setMaxOfWidthOrHeight(img.height > img.width ? "height" : "width");
                URL.revokeObjectURL(url);
            };
        } else {
            setMaxOfWidthOrHeight(null)
        }
    }, [userPhoto]);

    useEffect(() => {
        if (userCard && userCard.photoId && (userCard.photoId !== userPhotoId || !userPhotoId)) {
            fetch(`${defBaseUrl()}/api/v2/file?id=${userCard.photoId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${tokens?.accessToken}`
                },
            })
                .then(response => response.blob())
                .then(blob => {
                    setUserPhoto({userPhotoId: userCard!.photoId!, userPhoto: blob})
                })
        }
    }, [userCard]);

    return (
        <Link to="/profile"
              className="flex flex-row items-center gap-4 bg-white py-2 pl-3 border-b-[0.5px] border-b-solid border-b-slate-400/[.80] font-normal">
            {!tokens && <div className="border-[2px] border-black rounded-[3px]"><PiQuestionMarkThin
                className="h-9 w-9 stroke-2 rounded-full object-cover"/></div>}
            {tokens && !userCard?.photoId && <PiUserSquareThin className="w-14 h-14 -mx-2"/>}
            {tokens && userCard && userCard.photoId && !userPhoto && <PiImageSquareThin className="w-14 h-14 -mx-2"/>}
            {tokens && userCard && userCard.photoId && userPhoto &&
                <img src={`${URL.createObjectURL(userPhoto)}`} alt="Фото"
                     className={maxOfWidthOrHeight && maxOfWidthOrHeight === "height" ? "h-16" : "w-[80px]"}/>}
            <div className="flex flex-col gap-y-1 text-sm leading-4">
                <div className="mb-1">{displayName}</div>
                <div className="text-blue-500">{displayRole}</div>
            </div>
        </Link>
    )
}