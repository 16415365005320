import React, {useEffect, useState} from "react";
import {ProductionOrderFormRead} from "../../components/production-order/form/ProductionOrderFormRead";
import {useSearchParams} from "react-router-dom";
import {useGetProductionOrderQuery} from "../../store/backend-api/backend.api";
import {scrollStyle} from "../../utils/TailwindStyles";

export function ReadProductionOrderForm () {
    const [searchParams] = useSearchParams()
    const number = Number(searchParams.get("number"))
    const {data, isSuccess} = useGetProductionOrderQuery(number)
    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey((prevState) => prevState + 1)
    }, [data])

     return (
        <div key={key} className={scrollStyle}>
            {isSuccess && <ProductionOrderFormRead data={data}/>}
        </div>
    )
}